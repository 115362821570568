import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
  useParams,
} from "react-router-dom";
import $ from "jquery";
import useSession from "./SessionContext";
import Login from "../auth/login";
import ForgotPassword from "../auth/forgot-password";
import { ErrorPage } from "../components/Error";
import DashBoard from "../Dashboard";
import Register from "../auth/register";
import { Button, Dropdown, Flex, Menu, Space, Typography } from "antd";
import truckitezWhiteLogo from "../assets/img/logo_transparent_white.png";
import { Content, Header } from "antd/es/layout/layout";
import AppConstants from "../config/AppConstants";
import Upload from "../components/upload";
import Users from "../roles/users";
import { useEffect } from "react";
import LogOut from "../auth/logout";
import Settings from "../components/settings";
import Mines from "../roles/quarry/mines";
import Fleet from "../roles/trucker/fleet";
import Bids from "../roles/contractor/bids";
import Docs from "../roles/docs";
import { useState } from "react";
import BusinessProfile from "../roles/businessProfile";
import { FaCircleUser } from "react-icons/fa6";
import truckitnow_svg from "./../assets/img/truckit_now-cropped.svg";
import truckitlater_svg from "./../assets/img/truckit_later-cropped.svg";
import truckitmaybe_svg from "./../assets/img/truckit_maybe-cropped.svg";
import Jobs from "../roles/job_status/jobs";
import { MenuOutlined, MailOutlined } from "@ant-design/icons";
import Inbox from "../components/Inbox";
import InvoiceTemplate from "../InvoiceTemplate";
import ProjectDetails from "../screens/Projects/ProjectDetails.view";
import LoadProjects from "../screens/Projects/Projects.view";
import DriverProjects from "../screens/DriverProjects/DriverProjects.view";
import DriverProjectDetails from "../screens/DriverProjects/DriverProjectDetails.view";
import ManageDriverProject from "../components/modules/ManageDriverProject/ManageDriverProject.view";
import Invoices from "../screens/Invoices/Invoices.view";
import { isDriver, isContractor, isTruckingCompany } from "../utils/roles";
import DriverProjectDetailsContainer from "../screens/DriverProjects/DriverProjectDetails.container";
import PayTest from "../screens/PayTest";
import OngoingProjects from "../screens/OngoingProjects/OngoingProject.view";

export default function Router() {
  const Layout = () => {
    const { user, business, setBusiness, setUser } = useSession();

    const isRoleDriver = isDriver();
    const isRoleContractor = isContractor();
    const isRoleTruckingCompany = isTruckingCompany();

    const params = useParams();
    const navigate = useNavigate();
    const pos = "-1";
    let routePath = getRoute(params.job_status);
    const [route, setRoute] = useState(routePath);
    let URL = window.location.href;
    let isUploadUrl = !URL.includes("upload");

    $.ajaxSetup({
      complete: function (...args) {
        let resp = args[0];
        let isTokenExpired = false;
        try {
          isTokenExpired = resp.responseJSON.error.code;
        } catch (error) {}
        if (resp.readyState === 4 && resp.status === 401 && isTokenExpired) {
          setUser(null);
          setBusiness(null);
        }
      },
    });

    function getRoute(job_status) {
      switch (job_status) {
        case "bidded":
          return "K001-003";

        case "contracted":
          return "K001-004";

        case "dispatch":
          return "K001-005";

        case "inprogress":
          return "K001-006";

        case "completed":
          return "K001-007";

        default:
          return "K001-001";
      }
    }

    function getUserAccessibleRoutes(routes) {
      return routes.filter((n) => {
        if (n.children) {
          n.children = getUserAccessibleRoutes(n.children);
        }
        return n.roles.includes(user && user.role_id);
      });
    }
    let menuItems = getUserAccessibleRoutes(AppConstants().NAV_MENU);
    let isMobile = window.innerWidth <= 600;
    if (menuItems.length === 1 && isMobile) menuItems = menuItems[0].children;

    // menuItems[0].label = <div>
    //     <Space align="start">
    //         <MenuOutlined />
    //         <div style={{ paddingTop: '2px' }}>
    //             <img alt="TruckItEZ" width="100px" height="15px" src={truckitezLogo}></img>
    //         </div>
    //     </Space>
    // </div>;
    useEffect(() => {
      if (!user || !user.user_login) {
        return navigate("/", { replace: true });
      }
    });
    let headerItems = [];
    let main_nav = (
      <Menu
        overflowedIndicator={<MenuOutlined />}
        onClick={(e) => setRoute(e.key)}
        mode="horizontal"
        defaultSelectedKeys={[pos]}
        selectedKeys={[route]}
        items={menuItems}
        style={{
          flex: 1,
          minWidth: 0,
          borderBottom: "none",
        }}
      />
    );

    let nav_items = isUploadUrl
      ? AppConstants().NAV_SETTINGS.filter((n) =>
          n.roles.includes(user && user.role_id)
        )
      : AppConstants().NAV_SETTINGS.filter(
          (n) => n.roles.includes(user && user.role_id) && n.key !== "R00000"
        );

    let app_logo = (
      <div className="flex">
        <Typography.Link
          className="flex"
          onClick={(e) => {
            if (isUploadUrl) {
              if (isRoleDriver) {
                navigate("/driver/projects");
              } else if (isRoleContractor) {
                navigate("/projects");
              } else if (isRoleTruckingCompany) {
                navigate("/projects");
              }
            }
            setRoute("K001-001");
          }}
          alt={"TruckingEZ"}
        >
          <img
            alt="TruckingEZ"
            width="100px"
            height="22px"
            src={truckitezWhiteLogo}
          ></img>
        </Typography.Link>
        <div className="menu-spacer"></div>
      </div>
    );

    headerItems.push(app_logo);

    if (isUploadUrl) {
      headerItems.push(main_nav);
    }

    const selectJobType = (job_type) => {
      let userObj = { ...user };
      userObj["job_type"] = job_type;
      setUser(userObj);
      navigate(
        params.job_status ? "/projects/" + params.job_status : "/projects"
      );
    };

    if (isMobile) headerItems.reverse();

    return (
      <div>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {headerItems}
          {!isRoleDriver && isUploadUrl && (
            <Button
              type="link"
              style={{ color: "white", marginRight: "2px" }}
              onClick={() => navigate("/inbox")}
            >
              <MailOutlined /> Inbox
            </Button>
          )}
          <Flex justify={"flex-end"} style={{ marginLeft: "auto" }}>
            <Space mode={"vertical"}>
              <div style={{ paddingRight: "20px" }}>
                <Dropdown
                  menu={{
                    items: getUserAccessibleRoutes(
                      AppConstants().BUSINESS_MENU_SETTINGS
                    ),
                  }}
                >
                  <Typography.Link
                    className="text-white"
                    onClick={(e) => e.preventDefault()}
                    style={{ textTransform: "capitalize" }}
                  >
                    {isUploadUrl && (
                      <Button
                        type="link"
                        style={{ color: "white" }}
                        onClick={() => {
                          setRoute("");
                          navigate("/business-profile");
                        }}
                      >
                        {" "}
                        {business && business.profile.business_name}
                      </Button>
                    )}
                  </Typography.Link>
                </Dropdown>
              </div>
              <Dropdown menu={{ items: nav_items }}>
                <Typography.Link
                  className="text-white"
                  onClick={(e) => e.preventDefault()}
                  style={{ textTransform: "capitalize" }}
                >
                  <Flex align="center" style={{ textTransform: "capitalize" }}>
                    <FaCircleUser
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <span className="loggedin-user">
                      {user && [user.first_name, user.last_name].join(", ")}
                    </span>
                  </Flex>
                </Typography.Link>
              </Dropdown>
            </Space>
          </Flex>
        </Header>
        {window.location.pathname.startsWith("/projects") && (
          <center className="mt-8">
            <Flex
              className="flex-image-strip mt-8"
              justify={"space-evenly"}
              style={{ marginTop: "10px" }}
            >
              <img
                id="truckItNow"
                alt="TruckItNow"
                style={{
                  opacity: user && user.job_type === "now" ? 1 : 0.2,
                  cursor: "pointer",
                }}
                onClick={() => selectJobType("now")}
                width="150px"
                src={truckitnow_svg}
              ></img>
              <img
                id="truckItLater"
                alt="TruckItLater"
                style={{
                  opacity: user && user.job_type === "later" ? 1 : 0.2,
                  cursor: "pointer",
                }}
                onClick={() => selectJobType("later")}
                width="150px"
                src={truckitlater_svg}
              ></img>
              <img
                id="truckItMaybe"
                alt="TruckItMaybe"
                style={{
                  opacity: user && user.job_type === "maybe" ? 1 : 0.2,
                  cursor: "pointer",
                }}
                onClick={() => selectJobType("maybe")}
                width="150px"
                src={truckitmaybe_svg}
              ></img>
            </Flex>
          </center>
        )}
        <Content style={{ minHeight: "100vh" }} className="content">
          <div className="content-spacer">
            <Outlet />
          </div>
        </Content>
      </div>
    );
  };

  const BrowserRoutes = createBrowserRouter([
    { path: "/", element: <Login /> },
    { path: "/welcome", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/dashboard", element: <DashBoard /> },
        { path: "/users", element: <Users /> },
        { path: "/mines", element: <Mines /> },
        { path: "/fleet", element: <Fleet /> },
        { path: "/docs", element: <Docs /> },
        { path: "/bids", element: <Bids /> },
        { path: "/upload", element: <Upload /> },
        { path: "/documents", element: <Docs /> },
        { path: "/business-profile", element: <BusinessProfile /> },
        { path: "/settings", element: <Settings /> },
        { path: "/logout", element: <LogOut /> },
        { path: "/invoices", element: <Invoices /> },
        {
          path: "/invoice/:bid_id/:job_id/:company_id",
          element: <InvoiceTemplate />,
        },
        { path: "/invoice/:invoice_id", element: <InvoiceTemplate /> },
        { path: "/projects", element: <Jobs />, exact: true },
        {
          path: "/manage/projects/:job_id",
          element: <ProjectDetails />,
          exact: true,
        },

        {
          path: "/load-projects",
          element: <LoadProjects />,
          exact: true,
        },
        {
          path: "/ongoing-projects",
          element: <OngoingProjects />,
          exact: true,
        },
        {
          path: "/driver/projects",
          element: <DriverProjects />,
          exact: true,
        },

        {
          path: "/driver/projects/:assignment_id",
          element: <DriverProjectDetailsContainer />,
          exact: true,
        },
        {
          path: "/manage/driver/projects/:assignment_id",
          element: <ManageDriverProject />,
          exact: true,
        },
        { path: "/inbox", element: <Inbox /> },
        { path: "/pay-test", element: <PayTest /> },
        { path: "/projects/:job_status", element: <Jobs /> },
        { path: "/page-error", element: <ErrorPage /> },
        { path: "*", element: <ErrorPage /> },
      ],
    },
  ]);

  return <RouterProvider router={BrowserRoutes} />;
}
