import { Button, message } from "antd";
import { Form, Formik } from "formik";
import {
  driverSelectValidation,
  entryStatus,
  generateInitialData,
  quaryAndMineSelectionValidation,
  startDateValidation,
  truckSelectValidation,
} from "./ManageProject.utils";
import React, { useEffect, useState } from "react";
import SelectStartDateFormPhase from "./SelectStartDate.view";
import SelectTruckFormPhase from "./SelectTruck.view";
import SelectTruckDriverFormPhase from "./SelectTruckDriver";
import ConfirmSelectionFormPhase from "./ConfirmSelection.view";
import $projectManagement from "../../../store/manage-project";
import useTrucks from "../../../api/queries/useTrucks";
import useDrivers from "../../../api/queries/useDrivers";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useParams } from "react-router-dom";
import useHaulMutation from "../../../api/mutations/useHaulMutation";
import SelectQuarryAndMine from "./SelectQuarryAnMine";
import { isContractor } from "../../../utils/roles";

const ManageProject = ({
  onSuccess,
  isContractorMaterialSource = false,
  disabledDates = [],
  isHourlyJob = false,
}) => {
  const [currentFormPhase, setCurrentFormPhase] = useState(0);
  const isRoleContractor = isContractor();
  const validations = [
    startDateValidation,
    truckSelectValidation,
    driverSelectValidation,
    quaryAndMineSelectionValidation,
  ];

  const { job_id } = useParams();

  const { mutateAsync: haul, isPending: isHaulPending } = useHaulMutation({
    onSuccess: () => {
      onSuccess();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const { data: trucks, isLoading: trucksLoading } = useTrucks();
  const { data: drivers, isLoading: driversLoading } = useDrivers();

  const isFormDataLoading = trucksLoading || driversLoading;

  const [formPhases, setFormPhases] = useState([
    {
      title: "Select Start Date",
      render: () => <SelectStartDateFormPhase />,
    },
    {
      title: "Select Truck",
      render: () => <SelectTruckFormPhase />,
    },
    {
      title: "Select Driver",
      render: () => <SelectTruckDriverFormPhase />,
    },
    {
      title: "Select Quarry and Mine",
      render: () => <SelectQuarryAndMine />,
    },
    {
      title: "Confirm Selection",
      render: () => <ConfirmSelectionFormPhase />,
    },
  ]);

  useEffect(() => {
    if (isContractorMaterialSource || isHourlyJob) {
      setFormPhases((prev) => {
        prev.splice(3, 1);
        return prev;
      });
    }
  }, [isContractorMaterialSource, isHourlyJob]);

  const isLastFormPhase = currentFormPhase === formPhases.length - 1;

  const next = () => {
    if (currentFormPhase < formPhases.length - 1) {
      setCurrentFormPhase(currentFormPhase + 1);
    }
  };

  const back = () => {
    if (currentFormPhase > 0) {
      setCurrentFormPhase(currentFormPhase - 1);
    }
  };

  const selectedTruckId = $projectManagement.selectors.useSelectedTruckId();
  const selectedDriverId = $projectManagement.selectors.useSelectedDriverId();

  const addAnotherTruck = () => {
    if (isLastFormPhase) {
      setCurrentFormPhase(1);
    }
  };

  const projectManagerActions = $projectManagement.actions;

  if (isFormDataLoading) {
    return (
      <div className="flex items-center justify-center h-24 ">
        <LoadingOutlined />
      </div>
    );
  }

  const onSubmit = async (values) => {
    const filteredHaulers = values.haulers.filter(
      (hauler) => hauler.driver !== null
    );
    const haulers = filteredHaulers.map((hauler) => {
      return {
        truck_id: hauler._id,
        driver_id: hauler.driver._id,
        quarry_id: hauler.quarry_id,
        mine_id: hauler.mine_id,
      };
    });

    let payload = {
      ...values,
      date: moment(values.date.toDate()).format("YYYY-MM-DD"),
      haulers,
      job_id,
    };

    delete payload.drivers;

    await haul(payload);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <Formik
        initialValues={{
          ...generateInitialData(trucks, drivers),
          disabledDates,
        }}
        validationSchema={validations[currentFormPhase]}
        validateOnMount
        onSubmit={async (values, { resetForm, setFieldValue }) => {
          if (currentFormPhase < formPhases.length - 1) {
            next();
          } else {
            if (onSubmit) {
              await onSubmit(values);
              resetForm();
              setCurrentFormPhase(0);
            }
          }
        }}
      >
        {({ values, setFieldValue, submitForm, setTouched, validateForm }) => {
          return (
            <Form>
              <div className="flex flex-col items-center justify-center gap-4 w-full">
                <h1 className="text-lg font-semibold">
                  {formPhases[currentFormPhase].title}
                </h1>

                <div className="w-full mt-4">
                  {/* Form Views */}
                  {formPhases[currentFormPhase].render()}
                  {/* Form Views */}
                </div>

                {!isLastFormPhase && (
                  <div className="flex justify-center gap-4">
                    {currentFormPhase > 0 && (
                      <Button onClick={back}>Back</Button>
                    )}
                    <Button
                      onClick={async () => {
                        // const error = await validateForm();
                        // setTouched();
                        submitForm();
                      }}
                    >
                      Next
                    </Button>
                  </div>
                )}

                {isLastFormPhase && (
                  <div className="flex justify-center gap-4">
                    <Button
                      onClick={() => {
                        // Before adding another truck, set current data as Finalized;
                        setFieldValue(
                          selectedTruckId + ".status",
                          entryStatus.FINALIZED
                        );
                        setFieldValue(
                          selectedDriverId + ".status",
                          entryStatus.FINALIZED
                        );
                        projectManagerActions.setLastSelectedDriverId(null);
                        projectManagerActions.setLastSelectedTruckId(null);
                        addAnotherTruck();
                      }}
                    >
                      Add Another Truck
                    </Button>
                    <Button
                      onClick={() => {
                        setFieldValue(
                          selectedTruckId + ".status",
                          entryStatus.FINALIZED
                        );
                        setFieldValue(
                          selectedDriverId + ".status",
                          entryStatus.FINALIZED
                        );
                        projectManagerActions.setLastSelectedDriverId(null);
                        projectManagerActions.setLastSelectedTruckId(null);

                        // Delay submitForm to ensure state updates are completed
                        setTimeout(() => {
                          submitForm();
                        }, 0);
                      }}
                    >
                      {isHaulPending ? <LoadingOutlined /> : "Done"}
                    </Button>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ManageProject;
