import { Button, Card, Col, Form, Input, Row, Space } from "antd";
import SubmitButton from "./Button";
import Toastr from "toastr";
import useSession from "../context/SessionContext";
import { API } from "../config/AppConstants";
import $ from "jquery";
import { useState } from "react";

const Settings = () => {
  const { user, setUser } = useSession();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [save, setSave] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const onSave = (values) => {
    if (values.new_password !== values.confirm_password) {
      return Toastr.error("New password and Confirm password do not match !");
    }
  };

  const onSubmit = (values) => {
    setSave(true);
    let modifiedFields = getModifiedFields(user, values);
    let form_input = { user_login: user.user_login };
    for (const val in modifiedFields) {
      form_input[val] = modifiedFields[val];
    }
    let data = { form_input: form_input };
    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.LOGIN.USERS,
      type: "PUT",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          let userObj = { ...user };
          for (const modifiedVal in form_input) {
            userObj[modifiedVal] = form_input[modifiedVal];
          }
          setUser(userObj);
          setIsModified(false);
          Toastr.success("Basic Info Updated successfully!");
          setSave(false);
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
        setSave(false);
      }
    );
  };

  const valiDateNumber = (_, value) => {
    const regPattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (value && !regPattern.test(value)) {
      return Promise.reject("Please enter valid number.");
    }
    return Promise.resolve();
  };

  const onValuesChangeForm1 = (changedValues, allValues) => {
    let modifiedAttr = getModifiedFields(user, allValues);
    if (Object.keys(modifiedAttr).length === 0) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  };

  const getModifiedFields = (obj1, obj2) => {
    const differingValues = {};
    for (let key in obj1) {
      if (obj2.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        differingValues[key] = obj2[key];
      }
    }
    return differingValues;
  };

  return (
    <div>
      {user && (
        <Space direction="vertical" size={16}>
          <Card title="Basic Info:" style={{ width: "93vw" }}>
            <Form
              layout="vertical"
              autoComplete="off"
              initialValues={{
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
              }}
              onFinish={onSubmit}
              form={form1}
              onValuesChange={onValuesChangeForm1}
            >
              <Row>
                <Col xs={24} sm={24} md={11} lg={9} xl={9}>
                  <Form.Item name={"first_name"} label={"First Name"}>
                    <Input style={{ width: "250px" }} name="first_name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={7} xl={7}>
                  <Form.Item name={"last_name"} label={"Last Name"}>
                    <Input style={{ width: "250px" }} name="last_name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={11} lg={9} xl={9}>
                  <Form.Item name={"email"} label={"Email"}>
                    <Input style={{ width: "250px" }} name="email" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={7} xl={7}>
                  <Form.Item
                    name={"phone"}
                    label={"Phone"}
                    rules={[{ validator: valiDateNumber }]}
                  >
                    <Input style={{ width: "250px" }} name="phone" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: "center" }}>
                <Button disabled={!isModified} type="primary" htmlType="save">
                  {save ? "Saving..." : "Save"}
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <Card title="Login Info:" style={{ width: "93vw" }}>
            <Form
              layout="vertical"
              onFinish={onSave}
              autoComplete="off"
              form={form2}
              initialValues={{ user_login: user.user_login }}
            >
              <Row>
                <Col xs={24} sm={24} md={11} lg={9} xl={9}>
                  <Form.Item name={"user_login"} label={"User Login"}>
                    <Input disabled style={{ width: "250px" }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={7} xl={7}>
                  <Form.Item
                    name={"current_password"}
                    label={"Current Password"}
                    rules={[
                      {
                        required: true,
                        message: "Current Password is required!",
                      },
                    ]}
                  >
                    <Input.Password
                      style={{ width: "250px" }}
                      placeholder="Current Password"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={11} lg={9} xl={9}>
                  <Form.Item
                    name={"new_password"}
                    label={"New Password"}
                    rules={[
                      { required: true, message: "New Password is required!" },
                    ]}
                  >
                    <Input.Password
                      placeholder="New Password"
                      style={{ width: "250px" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={7} xl={7}>
                  <Form.Item
                    name={"confirm_password"}
                    label={"Confirm Password"}
                    rules={[
                      {
                        required: true,
                        message: "Confirm Password is required!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirm Password"
                      style={{ width: "250px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: "center" }}>
                <SubmitButton form={form2}>Save</SubmitButton>
              </Form.Item>
            </Form>
          </Card>
        </Space>
      )}
    </div>
  );
};

export default Settings;
