import { API } from "../../config/AppConstants";
import { apiHelper } from "../instance";

export const fetchDriverJobs = async ({
  date,
  method = "getDriverJobAssignments",
}) => {
  const { data } = await apiHelper.get(API.API_ENDPOINTS.TRUCKER.HAULS, {
    params: {
      date,
      method,
    },
  });
  return data;
};

export const startTrip = async (payload) => {
  const { data } = await apiHelper.post(
    API.API_ENDPOINTS.DRIVER.TRIPS,
    payload
  );
  return data;
};

export const updateTripStatus = async (payload) => {
  const { data } = await apiHelper.put(API.API_ENDPOINTS.DRIVER.TRIPS, payload);
  return data;
};

export const initiateHourlyTrip = async ({ job_assignment_id, trip_date }) => {
  const { data } = await apiHelper.post(
    API.API_ENDPOINTS.DRIVER.HOURLY_TRIP.INITIATE,
    { job_assignment_id, trip_date }
  );
  return data;
};

export const pauseHourlyTrip = async ({ trip_id, end_time, operation }) => {
  const { data } = await apiHelper.post(
    API.API_ENDPOINTS.DRIVER.HOURLY_TRIP.PAUSE,
    { trip_id, end_time, operation }
  );
  return data;
};

export const resumeHourlyTrip = async ({ trip_id, start_time }) => {
  const { data } = await apiHelper.post(
    API.API_ENDPOINTS.DRIVER.HOURLY_TRIP.RESUME,
    { trip_id, start_time }
  );
  return data;
};

export const uploadTripImages = async (payload, onProgress) => {
  if (payload.file === undefined) {
    return [];
  }

  const file = payload.file;

  const data = {
    trip_id: payload.trip_id,
    type: payload.type,
    file_extn: file.name.split(".").pop(),
    mime_type: file.type,
  };

  try {
    const response = await apiHelper.post(
      API.API_ENDPOINTS.DRIVER.UPLOAD_IMAGES,
      data,
      {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            onProgress?.(progress);
          }
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
