import React from "react";
import useInvoiceQuery from "../../api/queries/useInvoices";
import $sessionStore from "../../store/session";
import { Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import JobHelper from "../../helpers/jobHelper";

const Invoices = () => {
  const { company_id, role_id } = $sessionStore.actions.getUser();

  const { data, isLoading } = useInvoiceQuery(
    role_id === "R001" ? { job_company_id: company_id } : { company_id }
  );

  const navigate = useNavigate();

  if (isLoading) return <div>Loading...</div>;

  const viewInvoice = (data) => {
    const job = new JobHelper(data.job);
    navigate("/invoice/" + job.bid_id + "/" + job.jobId + "/" + company_id, {
      state: {
        isPartialInvoice: true,
        viewMode: true,
        haulIds: data.haul_ids,
        invoiceObjectId: data._id,
        invoices: data.invoice_items,
        isHourlyJob: job.isHourlyJob,
      },
    });
  };

  const columns = [
    {
      title: "Job ID",
      dataIndex: ["job", "sequence"],
      key: "job_id",
    },
    {
      title: "Project Name",
      dataIndex: ["job", "project_name"],
      key: "project_name",
    },
    {
      title: "Business Name",
      dataIndex: ["job", "business_name"],
      key: "business_name",
    },
    {
      title: "Job Complete Time",
      dataIndex: ["job", "job_date_ts"],
      key: "job_date_ts",
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: "Bid Close Time",
      dataIndex: ["job", "bid_closing_date_ts"],
      key: "bid_closing_date_ts",
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: "Est. Qty",
      dataIndex: ["job", "quantity"],
      key: "quantity",
    },
    {
      title: "Bid Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (total_amount) => {
        let total = 0;
        if (total_amount) {
          total = total_amount.toFixed(2);
        }
        return <span>{total}</span>;
      },
    },
    {
      title: "Total Est. Amount",
      key: "total_est_amount",
      render: (data, record) => {
        return (
          <span>
            {record.invoice_items
              .reduce(
                (acc, item) => acc + (isNaN(item.total) ? 0 : item.total),
                0
              )
              .toFixed(2)}
          </span>
        );
      },
    },
    {
      title: "",
      render: (data) => {
        return <Button onClick={() => viewInvoice(data)}>View Invoice</Button>;
      },
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={data} rowKey="_id" />
    </div>
  );
};

export default Invoices;
