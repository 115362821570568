export const unitFromHaulingType = (hauling_type) => {
  switch (hauling_type) {
    case "Hourly":
      return "Hour";
    case "Tons":
      return "Ton";
    case "Yards":
      return "Yard";
    default:
      return "Ton";
  }
};
