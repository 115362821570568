import { useMutation } from "@tanstack/react-query";
import { uploadTripImages } from "../services/drivers";
import { Mutations } from ".";

const useTripImageUpload = ({ onProgress, onSuccess } = {}) => {
  return useMutation({
    mutationFn: async (payload) => {
      const res = await uploadTripImages(payload, onProgress);
      let blobData = new Blob([payload.file], { type: payload.file.type });
      await fetch(res.url, {
        method: "PUT",
        body: blobData,
      });
      return res;
    },
    mutationKey: [Mutations.uploadTripImages],
    onSuccess,
  });
};

export default useTripImageUpload;
