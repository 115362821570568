import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { createRef, useEffect, useState } from "react";
import CameraIcon from "../../../icons/camera";
import { ErrorMessage, useFormikContext } from "formik";
import $diverProjectManagement from "../../../../store/manage-driver-project";
import useTripImageUpload from "../../../../api/mutations/useTripImageUpload";
import { useParams } from "react-router-dom";
import useHauls from "../../../../api/queries/useHauls";
import HaulAssignmentHelper from "../../../../helpers/haulAssignmentHelper";
import JobHelper from "../../../../helpers/jobHelper";
import AssignmentHelper from "../../../../helpers/assignmentHelper";

const QuarryPhotoPickerFormPhase = () => {
  const { assignment_id } = useParams();
  const inputKeyName = "photoAtQuary";
  const form = useFormikContext();
  const selectedFile = form.values.photoAtQuary;
  const ref = createRef();
  const props = {
    showUploadList: false,
    beforeUpload(file) {
      form.setFieldValue(inputKeyName, file);
      return false;
    },
  };

  const [preview, setPreview] = useState(null);

  const { data: hauls, isLoading: isHaulsLoading } = useHauls({
    assignment_id,
  });

  const haulsData = hauls[0];
  const haul = new HaulAssignmentHelper(haulsData);
  const assignmentData = haul.assignmentById(assignment_id);
  const assignment = new AssignmentHelper(assignmentData);
  const currentTrip = assignment.getTripByAssigmentId(assignment_id);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      // Clean up the object URL when the component is unmounted or when the file changes
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const removedPhoto = () => {
    form.setFieldValue(inputKeyName, null);
    setPreview(null);
  };

  const { mutate: uploadImage, isPending: isUploading } = useTripImageUpload({
    onSuccess: () => {
      $diverProjectManagement.actions.nextPhase();
    },
  });

  const onSubmit = async () => {
    form.setTouched();
    const errors = await form.validateForm();
    console.log(Object.keys(errors).length === 0);
    if (Object.keys(errors).length === 0) {
      if (selectedFile) {
        uploadImage({
          file: selectedFile,
          trip_id: currentTrip._id,
          type: "source",
        });
      }
    }
  };

  const skip = () => {
    $diverProjectManagement.actions.nextPhase();
  };

  return (
    <div className="flex justify-center">
      {}
      <div className="flex flex-col items-center gap-8">
        <div className="border-[1px] border-dashed border-[#0BA98D] w-96 h-96 rounded-xl relative">
          {preview != null ? (
            <div className="relative">
              <img src={preview} className="w-96 h-96 rounded-xl p-2" alt="" />
              <div
                onClick={removedPhoto}
                className="absolute top-4 right-4 w-6 h-6 rounded-[50%] flex justify-center items-center bg-[rgb(151,29,29)] cursor-pointer"
              >
                <CloseOutlined />
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                ref.current.click();
              }}
              className="flex justify-center items-center h-full cursor-pointer"
            >
              <div className="flex flex-col items-center w-28 gap-2">
                <CameraIcon />
                <h2 className="text-[#0BA98D] text-center font-bold">
                  Take a picture of the full truck
                </h2>
              </div>
            </div>
          )}

          <Upload {...props}>
            <Button ref={ref} hidden icon={<UploadOutlined />} />
          </Upload>
        </div>
        <ErrorMessage name={inputKeyName} />
        <div className="flex gap-4">
          <Button loading={isUploading} onClick={onSubmit}>
            Next
          </Button>
          <Button onClick={skip}>Skip</Button>
        </div>
      </div>
    </div>
  );
};

export default QuarryPhotoPickerFormPhase;
