import {
  Page,
  View,
  Document,
  Text,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import companyLogo from "../../../assets/img/logo.png";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
    padding: "20px",
  },
  mt8: {
    marginTop: "8px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  logo: {
    width: 100,
    objectFit: "cover",
  },
  h4Bold: {
    fontSize: 14,
    fontWeight: "bold",
  },
  h4: {
    fontSize: 14,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "1px solid #ddd",
    borderRadius: 4,
    padding: 10,
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ddd",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    borderBottom: "1px solid #ddd",
  },
  tableCell: {
    flex: 1,
    padding: 5,
    fontSize: "10px",
  },
});

const LabeledInfo = ({ title, description = [] } = {}) => {
  return (
    <View>
      <Text style={{ fontSize: 10, fontWeight: "bold", marginBottom: "2px" }}>
        {title}
      </Text>
      {description.map((e) => {
        return <Text style={{ fontSize: 10, marginBottom: "2px" }}>{e}</Text>;
      })}
    </View>
  );
};

const InvoicePdfTemplate = ({ invoice } = {}) => {
  if (!invoice) return null;

  const meta = invoice.meta;
  const isTaxExempt = invoice.isTaxExempt;
  const isHourlyJob =
    invoice.isHourlyJob == undefined ? false : invoice.isHourlyJob;
  const actualQuantitySymbol = isHourlyJob ? "Hours" : "Tons";
  const unitPriceSymbol = isHourlyJob ? "Hour" : "Ton";

  const calculateFullTotal = () => {
    return invoice.items
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);
  };

  const fullTotal = calculateFullTotal();

  return (
    <Document
      style={{
        height: "100vh",
      }}
    >
      <Page size="A4" style={styles.page}>
        <View style={{ marginBottom: 20 }}>
          <Image src={companyLogo} style={styles.logo} />
        </View>

        <View
          style={{
            ...styles.mt8,
            width: "100%",
          }}
        >
          <LabeledInfo
            title={meta.companyName}
            description={[meta.companyAddress]}
          />
        </View>

        <View
          style={{
            marginTop: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <LabeledInfo
            title={"Bill To:"}
            description={[meta.billTo.name, meta.billTo.address]}
          />

          <View>
            <LabeledInfo title={`Invoice Number: ${meta.invoiceNumber}`} />
            <LabeledInfo title={`Invoice Date: ${meta.invoiceDate}`} />
            <LabeledInfo title={`Due Date: ${meta.dueDate}`} />
            {isTaxExempt && isTaxExempt == true && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5px",
                }}
              >
                <View
                  style={{
                    color: "#ffffff",
                    paddingHorizontal: "8px",
                    borderRadius: "4px",
                    paddingVertical: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#dc4446",
                  }}
                >
                  <Text style={{ fontSize: "8px" }}>Tax Exempt</Text>
                </View>
              </View>
            )}
          </View>
        </View>

        <View style={{ marginTop: 20 }}>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={styles.tableCell}>Product</Text>
              <Text style={styles.tableCell}>Date</Text>
              <Text style={styles.tableCell}>Actual Quantity</Text>
              <Text style={styles.tableCell}>Unit Price</Text>
              <Text style={styles.tableCell}>Total Price</Text>
            </View>
            {invoice.items.map((item) => {
              const price = parseFloat(item.price);
              const quantity = parseFloat(item.quantity);
              return (
                <View key={item._id} style={styles.tableRow}>
                  <Text style={styles.tableCell}>{item.material}</Text>
                  <Text style={styles.tableCell}>
                    {new Date(item.date).toLocaleDateString()}
                  </Text>
                  <Text style={styles.tableCell}>
                    {quantity} {actualQuantitySymbol}
                  </Text>
                  <Text style={styles.tableCell}>
                    ${price.toFixed(2)} / {unitPriceSymbol}
                  </Text>
                  <Text style={styles.tableCell}>
                    ${(price * quantity).toFixed(2)}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
        <View
          style={{
            marginTop: "16px",
            paddingHorizontal: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <LabeledInfo title={"Total"} />
          <LabeledInfo title={`$${fullTotal}`} />
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdfTemplate;
