import { create } from "zustand";

import { immer } from "zustand/middleware/immer";

import { devtools } from "zustand/middleware";

const useDriverProjectManagementStore = create(
  devtools(
    immer((set, get) => ({
      currentPhase: 0,
      trips: [],
      onSiteHauling: {
        startTime: null,
      },
    }))
  )
);

const selectors = {
  useCurrentPhase: () =>
    useDriverProjectManagementStore((state) => state.currentPhase),
  useOnSiteHaulingStartTime: () =>
    useDriverProjectManagementStore((state) => state.onSiteHauling.startTime),
  useTrips: () => useDriverProjectManagementStore((state) => state.trips),
};

const actions = {
  set: useDriverProjectManagementStore.setState,
  reset: () => useDriverProjectManagementStore.setState({ currentPhase: 0 }),
  setOnSiteHaulingStartTime: (startTime) =>
    useDriverProjectManagementStore.setState({
      onSiteHauling: { startTime },
    }),

  nextPhase: () => {
    const currentState = useDriverProjectManagementStore.getState();
    if (currentState !== null) {
      useDriverProjectManagementStore.setState((prevState) => ({
        currentPhase: prevState.currentPhase + 1,
      }));
    }
  },
  setPhase: (phase) => {
    useDriverProjectManagementStore.setState({ currentPhase: phase });
  },
  prevPhase: () => {
    const currentState = useDriverProjectManagementStore.getState();
    if (currentState.currentPhase > 0) {
      useDriverProjectManagementStore.setState({
        currentPhase: currentState.currentPhase - 1,
      });
    }
  },
  addTrips: (trip) => {
    useDriverProjectManagementStore.setState((prevState) => ({
      trips: [...prevState.trips, trip],
    }));
  },
};

/**
 * **Damaged Items Store**
 *
 * this store is used to store damages items to handle in the form
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $diverProjectManagement = {
  actions,
  selectors,
};

export default $diverProjectManagement;
