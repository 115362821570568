import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const inputBgColor = "#fff";
const inputTextColor = "#000";

const defaultInputStyle = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#ccc8",
    color: inputTextColor,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    background: inputBgColor,
    color: inputTextColor,
    display: "none",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    color: inputTextColor,
    background: inputBgColor,
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    flex: "1",
    caretColor: "white",
  }),
};

const GooglePlaceAutoComplete = ({
  onSelect,
  defaultValue, // Add defaultValue prop
} = {}) => {
  return (
    <div className="w-full relative">
      <GooglePlacesAutocomplete
        apiKey={"AIzaSyC78V2SdOmNcqfDt03Eoat8rBTFOh1mkvo"}
        selectProps={{
          onChange: async (data) => {
            onSelect(data);
          },
          isClearable: true,
          styles: defaultInputStyle,
          placeholder: defaultValue ? defaultValue : "Enter Address..",
          defaultInputValue: defaultValue,
        }}
        autocompletionRequest={{
          types: ["address"],
        }}
        debounce={200}
      />
    </div>
  );
};

export default GooglePlaceAutoComplete;
