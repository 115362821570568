import React, { useState } from "react";
import { Button, Form, Table } from "antd";
import { useNavigate } from "react-router-dom";
import useJobs from "../../api/queries/useJobs";
import Loader from "../../components/primitives/Loader";
import moment from "moment";
import { capitalise } from "../../utils/string";

import { unitFromHaulingType } from "../../utils/units";

const OngoingProjects = () => {
  const navigate = useNavigate();

  const getColorByStatus = (status) => {
    const statusColors = {
      Hauling: "#00B336",
      assigned: "#6F55FF",
      Contracted: "#DA6944",
      pending_payment: "#e89a3c",
    };

    return statusColors[status] || "#000000";
  };

  const { data: jobs, isLoading } = useJobs({
    assignedTo: "contractor",
  });

  const columns = [
    {
      title: "Name",
      render: (job) =>
        job && job.project_name.length > 20
          ? `${job.project_name.substring(0, 20)}...`
          : job.project_name,
    },
    {
      title: "Location",
      render: (job) => {
        if (!job) return "N/A";

        return (
          <a
            href={`https://maps.google.com/maps?q=${job.gps}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {job.address
              ? `${
                  job.address.length > 30
                    ? job.address.substring(0, 30)
                    : job.address
                }...`
              : `View on Map`}
          </a>
        );
      },
    },
    {
      title: "Start Date / Time",
      render: (job) =>
        job && moment(Date(job.job_date_ts)).format("DD/MM/YYYY"),
    },
    {
      title: "Material",
      render: (job) => job && job.material,
    },
    {
      title: "Qty",
      render: (job) => (job && job.quantity) || "-",
    },
    {
      title: "Total Est. Amount",
      render: (job) => {
        const isHourlyJob = job.hauling_type === "Hourly";
        if (isHourlyJob) {
          return (
            <span>
              $ {job.budget}/{unitFromHaulingType(job.hauling_type)}
            </span>
          );
        }
        return (
          <span>$ {(job.quantity || job.hourly_minimum) * job.budget}</span>
        );
      },
      width: "50px",
    },
    {
      title: "Remaining",
      render: (job) => {
        const isHourlyJob = job.hauling_type === "Hourly";
        if (isHourlyJob) {
          return "-";
        }
        return job && job.quantity - (job.tons_completed ?? 0);
      },
    },
    {
      title: "% Complete",
      render: (job) => {
        if (!job) return "N/A";
        const totalQuantity = job.quantity;
        const completedQuantity = job.tons_completed ?? 0;
        const percentComplete = Math.round(
          (completedQuantity / totalQuantity) * 100
        );
        return (
          <div style={{ color: "#00B336" }}>
            {isNaN(percentComplete) ? "N/A" : `${percentComplete}%`}
          </div>
        );
      },
    },
    {
      title: "Status",
      render: (job) => {
        if (!job) return "N/A";
        const haulingStatus = job.haul_status ?? job.job_status;
        switch (haulingStatus) {
          default:
            if (!job) return "N/A";
            return (
              <Button
                style={{
                  borderRadius: "20px",
                  backgroundColor: getColorByStatus(haulingStatus),
                  color: "#FFFFFF",
                }}
                onClick={() => navigate(`/manage/projects/${job.job_id}`)}
              >
                {capitalise(haulingStatus)}
              </Button>
            );
        }
      },
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="space-y-4">
      <Table columns={columns} dataSource={jobs} />
    </div>
  );
};

export default OngoingProjects;
