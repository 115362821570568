import { useQuery } from "@tanstack/react-query";
import { Queries } from ".";
import { listInvoices } from "../services/invoice";

const useInvoiceQuery = ({ company_id, job_company_id }) => {
  return useQuery({
    queryKey: [Queries.invoices, company_id, job_company_id],
    queryFn: () => listInvoices({ company_id, job_company_id }),
  });
};

export default useInvoiceQuery;
