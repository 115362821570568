import { apiHelper, googleApiHelper } from "../instance";

class GoogleApiHelper {
  constructor() {
    this.apiKey = "AIzaSyC78V2SdOmNcqfDt03Eoat8rBTFOh1mkvo";
    this.geocodeUrl = "https://maps.googleapis.com/maps/api/geocode/json";
    this.autocompleteUrl =
      "https://maps.googleapis.com/maps/api/place/autocomplete/json";
  }

  async getAddressFromLatLng(lat, lng) {
    try {
      const response = await fetch(
        `${this.geocodeUrl}?latlng=${lat},${lng}&key=${this.apiKey}`
      );
      const data = await response.json();
      if (data.status === "OK") {
        return data.results[0].formatted_address;
      } else {
        throw new Error(data.error_message || "Failed to fetch address");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      throw error;
    }
  }

  async getCoordinatesFromAddress(address) {
    try {
      const response = await fetch(
        `${this.geocodeUrl}?address=${encodeURIComponent(address)}&key=${
          this.apiKey
        }`
      );
      const data = await response.json();
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        return {
          lat: location.lat,
          lng: location.lng,
          address: address,
        };
      } else {
        throw new Error(data.error_message || "Failed to fetch coordinates");
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      throw error;
    }
  }

  async getAutocompleteSuggestions(input) {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/place/js/AutocompletionService.GetPredictionsJson?1s${input}&key=${this.apiKey}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
      throw error;
    }
  }
}

export default GoogleApiHelper;
