import { capitalise } from "../utils/string";

class HaulAssignmentHelper {
  constructor(data) {
    this.data = data;
  }

  get status() {
    return capitalise(this.data.status);
  }

  get haulId() {
    return this.data._id;
  }

  get isExpired() {
    const moment = require("moment");
    const jobDate = moment.utc(this.data.date);
    const currentDate = moment().startOf("day");
    return jobDate.isBefore(currentDate);
  }

  get job() {
    return this.data.job;
  }

  get isExportJob() {
    return this.job.process_type === "export";
  }

  get completedPercentage() {
    const quantity = parseInt(this.data.job.quantity);
    const percent = (this.job.tons_completed / quantity) * 100;
    return percent.toFixed(0);
  }

  get tonsCompleted() {
    return this.data.tons_completed;
  }

  get remainingQuantity() {
    const quantity = parseInt(this.data.job.quantity);
    return quantity - this.tonsCompleted;
  }

  get assignments() {
    return this.data.assignments ?? [];
  }

  assignmentById(id) {
    return this.assignments.find((a) => a._id === id);
  }

  getLastAssignment() {
    return this.assignments.length > 0
      ? this.assignments[this.assignments.length - 1]
      : null;
  }

  set tonsCompleted(tons) {
    this.data.tons_completed = tons;
  }
}

export default HaulAssignmentHelper;
