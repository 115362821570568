import { API } from "../../config/AppConstants";
import $sessionStore from "../../store/session";
import { apiHelper } from "../instance";

export const fetchJobs = async ({
  job_status,
  assignedTo,
  useGet = 0,
  job_id,
}) => {
  const { company_id } = $sessionStore.actions.getUser();
  const { data } = await apiHelper.get(API.API_ENDPOINTS.CONTRACTOR.JOBS, {
    params: {
      company_id: company_id,
      useGet,
      job_status,
      assignedTo,
      job_id,
    },
  });
  return data;
};

export const createJob = async (payload) => {
  const user = $sessionStore.actions.getUser();
  const { data } = await apiHelper.post(
    API.API_URL + API.API_VER + API.API_ENDPOINTS.CONTRACTOR.JOBS,
    payload,
    {
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      params: {
        private: payload.private ?? "true",
      },
    }
  );
  return data;
};

export const makePayment = async (payload) => {
  const { data } = await apiHelper.post(
    API.API_URL + API.API_VER + API.API_ENDPOINTS.CONTRACTOR.JOBS,
    payload,
    {
      params: {
        action: "makePayment",
      },
    }
  );
  return data;
};
