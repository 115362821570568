// src/components/InvoiceTemplate.js
import React, { useEffect, useState } from "react";

import {
  Layout,
  Typography,
  Row,
  Col,
  Table,
  Form,
  InputNumber,
  Input,
  Button,
  Modal,
  Spin,
} from "antd";

import truckitezLogo from "./assets/img/logo_transparent.png";
import useSession from "./context/SessionContext";
import SubmitButton from "./components/Button";
import { API } from "./config/AppConstants";
import $ from "jquery";
import Toastr from "toastr";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useSubmitInvoiceMutation from "./api/mutations/useSubmitInvoice";
import AssignmentHelper from "./helpers/assignmentHelper";
import useJob from "./api/queries/useJob";
import Loader from "./components/primitives/Loader";
import JobHelper from "./helpers/jobHelper";
import moment from "moment";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import InvoicePdfTemplate from "./components/pdf/templates/Invoice.view";

const { Content } = Layout;
const { Text } = Typography;
let invoiceData = {};

const InvoiceTemplate = () => {
  let params = useParams();
  const { data: jobDetails, isLoading: jobDetailsLoading } = useJob(
    params.job_id
  );

  if (jobDetailsLoading || !jobDetails) {
    return <Loader />;
  }

  return <InvoiceTemplateView jobDetails={jobDetails} />;
};

const InvoiceTemplateView = ({ jobDetails } = {}) => {
  const jobHelper = new JobHelper(jobDetails);
  let params = useParams();
  const location = useLocation();
  const {
    isPartialInvoice = false,
    haulIds = [],
    viewMode = false,
  } = location.state || {};

  const { mutate: submitInvoice, isPending: isPartialInvoicePending } =
    useSubmitInvoiceMutation({
      options: {
        onSuccess: () => {
          Toastr.success("Invoice submitted successfully!");
          navigate("/invoices");
        },
      },
    });

  // Create styles

  const navigate = useNavigate();
  let { user } = useSession();

  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [item, setItem] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const noInvoicesToBeSubmitted = item && item.length === 0;

  useEffect(() => {
    setLoading(true);
    getInvoiceDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const invoiceInfo = (trucker, contractor) => {
    let truc_bill_address = trucker.billing_address;
    truc_bill_address =
      truc_bill_address && typeof truc_bill_address == "object"
        ? truc_bill_address
        : JSON.parse(truc_bill_address);
    let conc_bill_address = contractor.billing_address;
    conc_bill_address =
      conc_bill_address && typeof conc_bill_address == "object"
        ? conc_bill_address
        : JSON.parse(conc_bill_address);
    let dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + 1);
    invoiceData = {
      companyName: trucker.business_name,
      companyAddress:
        truc_bill_address &&
        truc_bill_address.address +
          "," +
          truc_bill_address.city +
          "," +
          truc_bill_address.state +
          "," +
          truc_bill_address.zipcode,
      invoiceNumber: "INV-0001",
      invoiceDate: new Date().toLocaleDateString(),
      dueDate: dueDate.toLocaleDateString(),
      billTo: {
        name: contractor.business_name,
        address:
          conc_bill_address &&
          conc_bill_address.address +
            "," +
            conc_bill_address.city +
            "," +
            conc_bill_address.state +
            "," +
            conc_bill_address.zipcode,
      },
    };
  };

  // const fetchTruckerPrfl = (company_id) => {
  //   return new Promise((resolve, reject) => {
  //     $.ajax({
  //       url:
  //         API.API_URL +
  //         API.API_VER +
  //         API.API_ENDPOINTS.LOGIN.BUSINESS_PRFL +
  //         "?company_id=" +
  //         company_id,
  //       type: "GET",
  //       headers: {
  //         user_login: user.user_login,
  //         user_token: user.user_token,
  //       },
  //       dataType: "json",
  //     })
  //       .then(async (res) => {
  //         resolve(res);
  //       })
  //       .fail(function (jqXHR, textStatus, errorThrown) {
  //         resolve("failed to fetch the business profile!", jqXHR);
  //       });
  //   });
  // };

  const getInvoiceDetails = () => {
    if (!user) return;
    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.INVOICE.INVOICEINFO +
        "?bid_id=" +
        params.bid_id +
        "&job_id=" +
        params.job_id,
      type: "GET",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    })
      .then(async (res) => {
        let job = { ...res.jobInfo };
        job.bid = res.bidInfo;
        job = [job];

        let uninvoicedHauls = res.uninvoicedHauls;

        let haulIdSets = new Set(haulIds);

        if (isPartialInvoice || viewMode) {
          uninvoicedHauls = uninvoicedHauls.filter((e) =>
            haulIdSets.has(e._id)
          );
        }

        let uninvoicedHaulsTemp = [];
        for (let i = 0; i < uninvoicedHauls.length; i++) {
          let e = uninvoicedHauls[i];
          if (e.invoiced !== true || viewMode === true) {
            e.material = jobDetails.material;
            e.bid = res.bidInfo;
            e.price = res.bidInfo.bid_amount;
            if (jobHelper.isHourlyJob) {
              e.quantity = AssignmentHelper.calculateTotalHours(e.trips);
            } else {
              e.quantity = e.tons_completed;
            }
            setTotalAmount((prev) => prev + e.quantity * e.price);
            uninvoicedHaulsTemp.push(e);
          }
        }

        setItem(uninvoicedHaulsTemp);
        invoiceInfo(res.bidBusinessProfile, res.jobBusinessProfile);
        setLoading(false);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "material",
      render: (material, record, i) => {
        return (
          <span
            className={
              user.role_id === "R001" ? "" : viewMode ? "" : "anchor_link"
            }
          >
            {material}
          </span>
        );
      },
      onCell: (record, recordIndex) => ({
        onClick: () => {
          user.role_id !== "R001" && onCellSelected(record);
        },
      }),
    },

    {
      title: "Date",
      dataIndex: "date",
      render: (date, record, i) => {
        return <span>{moment(date).format("MMMM Do YYYY")}</span>;
      },
    },
    {
      title: "Actual Quantity",
      dataIndex: "quantity",
      render: (quantity, record, i) => {
        if (jobHelper.isHourlyJob) {
          return <span>{quantity} Hrs</span>;
        }
        return <span>{quantity} Tons</span>;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "bid",
      render: (bid, record, i) => {
        if (jobHelper.isHourlyJob) {
          return <span>$ {bid.bid_amount}/hr</span>;
        }
        return <span>$ {bid.bid_amount}/ton</span>;
      },
    },
    {
      title: "Total Price",
      dataIndex: "bid",
      render: (bid, record, i) => {
        return <span>$ {(bid.bid_amount * record.quantity).toFixed(2)}</span>;
      },
    },
  ];

  const onFinish = (values) => {
    if (selectedRow) {
      setItem((prevItems) => {
        const index = prevItems.findIndex(
          (item) => item._id === selectedRow._id
        );
        const tempItem = prevItems[index];
        const prevTotal = tempItem.quantity * tempItem.price;
        const newTotal = values.actual_quantity * values.price;
        tempItem.quantity = values.actual_quantity;
        tempItem.price = values.price;
        setTotalAmount((prev) => {
          let prevTemp = prev;
          prevTemp -= prevTotal;
          prevTemp += newTotal;
          return prevTemp;
        });
        setModalVisible(false);
        return prevItems;
      });
    } else {
      let itemVal = JSON.parse(JSON.stringify(item));
      const uniqueId = new Date().getTime().toString();
      values._id = uniqueId;
      let bid = JSON.parse(JSON.stringify(itemVal[0].bid));
      bid.bid_amount = values.price;
      values.bid = JSON.parse(JSON.stringify(itemVal[0].bid));
      itemVal.push({ ...values, bid });
      setItem(itemVal);
      setTotalAmount((prev) => prev + values.quantity * values.price);
      setModalVisible(false);
    }
  };

  const onCellSelected = (record) => {
    if (viewMode) return;
    setSelectedRow(record);
    form.setFieldsValue({
      price: record.bid.bid_amount,
      quantity: record.quantity || record.hourly_minimum,
      material: record.material,
      actual_quantity: !record.actual_quantity
        ? record.hourly_minimum || record.quantity
        : record.actual_quantity,
    });
    setModalVisible(true);
  };

  const addItem = () => {
    form.setFieldsValue({ material: "", quantity: "", unitPrid: "" });
    setModalVisible(true);
  };

  const submitPartialInvoice = () => {
    let itemData = [];
    let haulIds = [];
    let total = 0;
    item.forEach((itemInfo) => {
      let sum = parseFloat((itemInfo.price * itemInfo.quantity).toFixed(2));
      total += sum;
      haulIds.push(itemInfo._id);
      itemData.push({
        product: itemInfo.material,
        quantity: parseInt(itemInfo.quantity),
        actual_quantity: itemInfo.quantity,
        unit_price: itemInfo.price,
        total: sum,
      });
    });

    const bidCompanyId = jobDetails?.bids[0]?.company_id;

    let data = {
      form_input: {
        job_id: params.job_id,
        bid_id: params.bid_id,
        haul_ids: haulIds,
        bid_company_id: bidCompanyId,
        job_company_id: item?.[0]?.company_id,
        items: itemData,
        total_amount: total,
        partial: true,
        action: "submitInvoice",
      },
    };

    submitInvoice(data);
  };

  const submitHourlyInvoice = () => {
    let itemData = [];
    let haulIds = [];
    let total = 0;
    item.forEach((itemInfo) => {
      const sum = parseFloat((itemInfo.price * itemInfo.quantity).toFixed(2));
      total += sum;
      haulIds.push(itemInfo._id);
      itemData.push({
        product: itemInfo.material,
        hoursCompleted: parseFloat(itemInfo.quantity),
        total: sum,
        unitPrice: itemInfo.price,
      });
    });

    const bidCompanyId = jobDetails?.bids[0]?.company_id;

    let data = {
      form_input: {
        job_id: params.job_id,
        bid_id: params.bid_id,
        haul_ids: haulIds,
        bid_company_id: bidCompanyId,
        job_company_id: item?.[0]?.company_id,
        items: itemData,
        total_amount: total,
        partial: true,
        action: "submitInvoice",
      },
    };

    submitInvoice(data);
  };

  const handleSubmit = () => {
    if (jobHelper.isHourlyJob) {
      submitHourlyInvoice();
      return;
    }
    if (isPartialInvoice && !jobHelper.isHourlyJob) {
      submitPartialInvoice();
      return;
    }

    const bidCompanyId = jobDetails?.bids[0]?.company_id;

    let itemData = [];
    let haulIds = [];
    let total = 0;
    for (const itemInfo of item) {
      haulIds.push(itemInfo._id);
      itemData.push({
        product: itemInfo.material,
        quantity: itemInfo.quantity,
        actual_quantity: itemInfo.quantity,
        unitPrice: itemInfo.bid.bid_amount,
        total: itemInfo.bid.bid_amount * itemInfo.quantity,
      });
      total += itemInfo.bid.bid_amount * itemInfo.quantity;
    }

    let data = {
      form_input: {
        job_id: params.job_id,
        bid_id: params.bid_id,
        bid_company_id: bidCompanyId,
        job_company_id: item?.[0]?.company_id,
        items: itemData,
        haul_ids: haulIds,
        total_amount: total,
        action: "submitInvoice",
      },
    };

    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.INVOICE.INVOICEINFO,
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          navigate("/projects/completed");
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
      }
    );
  };

  return (
    <React.Fragment>
      {Object.keys(invoiceData).length > 0 && !loading ? (
        <Layout>
          <Content style={{ padding: "50px" }}>
            <Row>
              <Col span={12}>
                <Typography.Link alt={"TruckingEZ"}>
                  <img
                    style={{ marginBottom: "45px", borderRadius: "5px" }}
                    alt="TruckingEZ"
                    width="100px"
                    height="18px"
                    src={truckitezLogo}
                  ></img>
                </Typography.Link>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Text strong>{invoiceData.companyName}</Text>
                <br />
                {/* <Text strong>{invoiceData.projectName}</Text><br /> */}
                <Text>{invoiceData.companyAddress}</Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text strong>Invoice #: </Text>
                {invoiceData.invoiceNumber}
                <br />
                <Text strong>Invoice Date: </Text>
                {invoiceData.invoiceDate}
                <br />
                <Text strong>Due Date: </Text>
                {invoiceData.dueDate}
                <br />
                {jobHelper.isTaxExempt && (
                  <Button
                    size="small"
                    type="primary"
                    className=" pointer-events-none mt-2"
                  >
                    <Typography.Link>Tax Exempt</Typography.Link>
                  </Button>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col span={12}>
                <Text strong>Bill To:</Text>
                <br />
                <Text>{invoiceData.billTo.name}</Text>
                <br />
                <Text>{invoiceData.billTo.address}</Text>
                <br />
              </Col>
            </Row>

            {/*   {user && user.role_id !== "R001" && !isPartialInvoice && (
              <Row>
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    type="primary"
                    style={{ marginTop: "5px" }}
                    onClick={() => addItem()}
                  >
                    Add Item
                  </Button>
                </Col>
              </Row>
            )}
*/}

            <div
              className="ant-table-custom-wrapper"
              style={{ marginTop: "15px" }}
            >
              <Table
                columns={columns}
                dataSource={item}
                pagination={false}
                summary={() => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      colSpan={4}
                      style={{ textAlign: "right" }}
                    >
                      <Text strong>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text strong>$ {totalAmount.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              />
            </div>

            {viewMode && item && (
              <div className="m-4 flex justify-end">
                <PDFDownloadLink
                  fileName="Invoice"
                  document={
                    <InvoicePdfTemplate
                      invoice={{
                        meta: invoiceData,
                        items: item,
                        isHourlyJob: jobHelper.isHourlyJob,
                        isTaxExempt: jobHelper.isTaxExempt,
                      }}
                    />
                  }
                >
                  Download Invoice
                </PDFDownloadLink>
              </div>
            )}

            {!noInvoicesToBeSubmitted &&
              !viewMode &&
              user.role_id !== "R001" && (
                <Col
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    marginTop: "15px",
                  }}
                >
                  <Button
                    loading={isPartialInvoicePending}
                    disabled={isPartialInvoicePending}
                    type="primary"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                </Col>
              )}
          </Content>
        </Layout>
      ) : (
        <div className="center-container">
          <Spin tip="Loading"></Spin>
        </div>
      )}

      <Modal
        title={selectedRow ? "Edit Item" : "Add Item"}
        open={modalVisible}
        width={600}
        onCancel={() => {
          setModalVisible(false);
          setSelectedRow(null);
        }}
        style={{ top: 60 }}
        destroyOnClose={true}
        footer={false}
      >
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            maxWidth: "100%",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="nest-messages"
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 22 }}
            style={{ maxWidth: 700, margin: "15px 30px" }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item label={"Description"} name={"material"}>
                  <Input disabled={selectedRow && selectedRow.job_id} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={selectedRow ? "Actual Quantity" : "Quantity"}
                  name={selectedRow ? "actual_quantity" : "quantity"}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item label={"Unit Price"} name={"price"}>
                  <InputNumber
                    disabled={selectedRow && selectedRow.job_id}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Col
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "15px",
                marginRight: "18px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setModalVisible(false);
                  setSelectedRow(null);
                }}
              >
                Cancel
              </Button>
              <SubmitButton form={form}>{"Submit"}</SubmitButton>
            </Col>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default InvoiceTemplate;
