// src/MapPicker.js
import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Button } from "antd";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: -3.745,
  lng: -38.523,
};

function MapPicker(props) {
  const [center, setCenter] = useState(defaultCenter);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // Get the user's current location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          console.error("Error fetching the geolocation");
        }
      );
    }
  }, []);

  const onMapClick = useCallback((event) => {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDiCpqEF4u7BSh4L-sVxq1uC5hOeUJsF6I",
  });

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onClick={onMapClick}
      >
        {selectedLocation && <Marker position={selectedLocation} />}
      </GoogleMap>
      <div
        style={{ marginTop: "20px", display: "flex", justifyContent: "end" }}
      >
        <Button
          onClick={() => {
            if (!selectedLocation) return;
            props.setLocation({
              lat: selectedLocation.lat,
              lng: selectedLocation.lng,
            });
          }}
        >
          Confirm location
        </Button>
      </div>
    </div>
  ) : (
    ""
  );
}

export default React.memo(MapPicker);
