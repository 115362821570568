import { Button, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";

const ContractForm = ({
  modalVisible,
  onCancel,
  onAccept,
  file,
  disabledAcceptButton,
  onContractViewed,
} = {}) => {
  const pageRefs = useRef([]);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.length === 1 &&
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const pageNumber = parseInt(entry.target.dataset.pageNumber, 12);
              if (numPages === pageNumber || pageNumber === numPages - 1) {
                if (onContractViewed) {
                  onContractViewed();
                }
              }
            }
          });
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: "0px",
        threshold: 0.1, // Adjust the threshold value as needed
      }
    );

    pageRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      if (pageRefs.current) {
        pageRefs.current.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref);
          }
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numPages]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    pageRefs.current = Array(numPages).fill(null);
  };

  return (
    <div>
      <Modal
        title="Acknowledgment"
        maskClosable={false}
        open={modalVisible}
        width={680}
        onCancel={onCancel}
        style={{ top: 20 }} // Adjust the top position of the modal
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            disabled={disabledAcceptButton}
            style={{ marginRight: "10px" }}
            key="accept"
            type="primary"
            onClick={onAccept}
          >
            Accept
          </Button>,
        ]}
      >
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            maxWidth: "100%",
          }}
        >
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                inputRef={(el) => (pageRefs.current[index] = el)}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
      </Modal>
    </div>
  );
};

export default ContractForm;
