import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";

import useSession from "../../context/SessionContext";
import $sessionStore from "../../store/session";
import { API } from "../../config/AppConstants";
import $ from "jquery";
import Toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import UTIL from "./../../Util";
import { Document, Page, pdfjs } from "react-pdf";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import CreateJobFormModal from "../../components/modules/CreateJobModal";
import PaymentModal from "../../components/modules/Payment/PaymentModal.view";
import useMakePayment from "../../api/mutations/useMakePayment";
import { unitFromHaulingType } from "../../utils/units";
import biddingContract from "../../assets/docs/bidding-contract.pdf";
import ContractForm from "../../components/modules/ContractForm/ContractForm.view";
import useCreateJobMutation from "../../api/mutations/userCreateJob";
import { invalidateQueries } from "../../api/queryClient";
import { Queries } from "../../api/queries";
import moment from "moment";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Bids = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const params = useParams();
  let { business } = useSession();
  const { company_id } = $sessionStore.actions.getUser();
  const user = $sessionStore.actions.getUser();
  const [paymentPayload, setPaymentPayload] = useState({});

  const [selectedBid, setSelectedBid] = useState(null);

  let navigate = useNavigate();
  const [form] = Form.useForm();

  const tagColors = {
    Pending: "#e89a3c",
    InProgress: "#e774bb",
    Dispatch: "rgb(201 154 84)",
    pending_payment: "#e89a3c",
    Billed: "gold",
    Contracted: "#2db1a8",
    Declined: "#9a3615",
    ContractorDeclined: "#9a3615",
    Completed: "#4c9c33",
    "Invoice Generated": "#008080",
  };

  const [activePage, setactivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const [pdfError, setPdfError] = useState(null);
  const [pdfKey, setPdfKey] = useState(0);

  const [isContractModalVisible, setIsContractModalVisible] = useState(false);

  useEffect(() => {
    if (pdfModalVisible) {
      setIsPdfLoaded(false);
      setNumPages(null);
      setPdfError(null);
      setPdfKey((prevKey) => prevKey + 1);
    }
  }, [pdfModalVisible, pdfUrl]);

  const handleViewCreditApplication = (record) => {
    const s3PdfUrl = record?.credit_application?.SecureUrl;
    setPdfUrl(s3PdfUrl);
    setCurrentRecord(record);
    setIsPdfLoaded(false);
    setPdfError(null);
    setPdfModalVisible(true);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsPdfLoaded(true);
    setPdfError(null);
  };

  const onDocumentLoadError = (error) => {
    console.error("Error loading PDF:", error);
    setPdfError("Failed to load PDF. Please try again.");
    setIsPdfLoaded(false);
  };

  const { mutate: makePayment, isPending: isPaymentLoading } = useMakePayment({
    onSuccess: () => {
      refetchJobs();
      Toastr.success("Successfully accepted bid!");
      setIsModalVisible(false);
      setSelectedBid(null);
      setIsContractModalVisible(false);
    },
  });

  const handlePdfCaVerification = (record, verification) => {
    const { company_id } = $sessionStore.actions.getUser();

    $.ajax({
      url:
        API.API_URL + API.API_VER + API.API_ENDPOINTS.CA_VERIFICATION.ACCEPT_CA,
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify({
        contractor_id: company_id,
        trucker_id: record.company_id,
        verified: verification,
      }),
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    }).then(
      (res) => {
        if (res) {
          Toastr.success("Credit Application Verified successfully!");
          refetchJobs();
        }
      },
      (err) => {
        console.log(err);
        Toastr.error("Something went wrong!");
      }
    );
  };

  const refetchJobs = () => {
    props.fetchJobs(props.jobStatus);
  };

  const handlePdfAccept = () => {
    handlePdfCaVerification(currentRecord, true);
    setPdfModalVisible(false);
  };

  const handlePdfDecline = () => {
    handlePdfCaVerification(currentRecord, false);
    setPdfModalVisible(false);
  };

  const bids_columns = [
    {
      title: "",
      dataIndex: "ca_verification",
      width: "50px",
      render: (ca_verification, record, i) => {
        if (!record.ca_verification) {
          return <span></span>;
        }
        return (
          <span>
            {record.ca_verification.verified ? (
              <IoIosCheckmarkCircle color="green" size={20} />
            ) : (
              <IoIosCloseCircle color="red" size={20} />
            )}
          </span>
        );
      },
    },
    {
      title: "Business Name",
      dataIndex: "business_name",
      render: (business_name, record, i) => {
        return <span>{business_name}</span>;
      },
      width: "auto",
    },
    {
      title: "Bid Details",
      dataIndex: "bid_amount",
      render: (bid_amount, record, i) => {
        const materialPrice = record?.materialPrice ?? 0;
        const haulRate = record?.haulRate ?? 0;
        const isHourlyJob = record.hauling_type === "Hourly";
        const detailedPriceExist = materialPrice + haulRate > 0;

        if (!bid_amount) {
          return <span className="text-gray-500">-</span>;
        }

        return (
          <table className=" rounded-md">
            <tbody>
              <tr>
                {!isHourlyJob && (
                  <React.Fragment>
                    <td className="text-left">
                      <div className="text-sm text-gray-700">
                        <span className="font-medium">Material Price:</span>
                        <span className="ml-1 text-blue-600">
                          $ {record.materialPrice}
                        </span>
                      </div>
                    </td>
                    <td className="text-left">
                      <div className="text-sm text-gray-700">
                        <span className="font-medium">Haul Rate:</span>
                        <span className="ml-1 text-blue-600">
                          $ {record.haulRate}
                        </span>
                      </div>
                    </td>
                  </React.Fragment>
                )}
                <td className="text-left">
                  <div className="text-sm font-semibold text-gray-900 flex items-center gap-1">
                    <span>$ {bid_amount}</span>
                    <span className="text-sm text-gray-500">
                      /
                      {record.hauling_type === "Hourly"
                        ? "Hr"
                        : record.hauling_type}
                    </span>
                  </div>
                </td>
                {!isHourlyJob && (
                  <td className="text-left">
                    <div className="text-sm text-gray-700">
                      <span className="font-medium">Total Amount:</span>
                      <span className="ml-1 text-green-600">
                        $ {record.totalAmount}
                      </span>
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        );
      },
      width: "auto",
    },
    {
      title: "Actions",
      render: (action, record) => {
        let content;
        switch (record.contractor_choice) {
          case "Contracted":
          case "Declined":
            content = (
              <Tag color={tagColors[record.contractor_choice]}>
                {record.contractor_choice}
              </Tag>
            );
            break;

          // case "pending_payment":
          //   if (
          //     record.hasContractorPaid == true &&
          //     record.hasTruckerPaid == false
          //   ) {
          //     return "Trucker Payment Pending";
          //   }

          //   break;
          case "Pending":
            if (record?.ca_verification?.verified) {
              content = (
                <div>
                  <Button
                    onClick={() => handleAccept(record, "declined")}
                    size="small"
                  >
                    Decline
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      setSelectedBid(record);
                      setIsContractModalVisible(true);
                    }}
                    style={{ marginLeft: "10px" }}
                    size="small"
                    type="primary"
                  >
                    Accept
                  </Button>
                </div>
              );
            } else {
              content = (
                <React.Fragment>
                  <Button
                    onClick={() => handleViewCreditApplication(record)}
                    size="small"
                  >
                    View Credit Application
                  </Button>
                </React.Fragment>
              );
            }

            break;

          default:
            content = (
              <Tag color={tagColors[record.contractor_choice]}>
                {record.contractor_choice}
              </Tag>
            );
            break;
        }
        return <div>{content}</div>;
      },
      width: "auto",
    },
  ];

  const jobs_columns = [
    {
      title: "Project Name",
      dataIndex: "project_name",
      render: (job_type, record, i) => {
        return (
          <Badge
            style={{
              zIndex: 0,
            }}
            color={"white"}
            count={record.bids ? record.bids.length : 0}
          >
            <span> {job_type} </span>&nbsp;&nbsp;&nbsp;
          </Badge>
        );
      },
      width: "auto",
    },
    {
      title: "Job Status",
      dataIndex: "job_status",
      render: (job_status, record, i) => {
        return (
          <Tag color={tagColors[job_status]}>
            {job_status === "pending_payment" ? "Pending Payment" : job_status}
          </Tag>
        );
      },
      width: "auto",
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (trucking_company, record, i) => {
        let address = record.address ? record.address : "-";
        return (
          <Typography.Text>
            {address.length > 25 ? (
              <Tooltip title={address}>
                {address.substring(0, 25) + "..."}
              </Tooltip>
            ) : (
              address
            )}
          </Typography.Text>
        );
      },
      width: "auto",
    },
    {
      title: "Material",
      dataIndex: "material",
      render: (material, record, i) => {
        return (
          <span style={{ display: "block", width: "120px" }}>{material}</span>
        );
      },
      width: "auto",
    },
    {
      title: "Job Start Date",
      dataIndex: "job_date_ts",
      render: (job_date_ts, record, i) => {
        return (
          <span style={{ display: "block", width: "150px" }}>
            {moment.utc(job_date_ts).format("MM-DD-YYYY")}
          </span>
        );
      },
      width: "auto",
    },
    {
      title: (
        <span style={{ display: "block", width: "90px" }}>Hauling Type</span>
      ),
      dataIndex: "hauling_type",
      render: (hauling_type, record, i) => {
        return <span>{hauling_type}</span>;
      },
      width: "auto",
    },
    {
      title: "Est. Qty",
      dataIndex: "quantity",
      render: (budget, record) => {
        const isHourlyJob = record.hauling_type === "Hourly";
        if (isHourlyJob) {
          return "-";
        }
        return <span>{record.quantity}</span>;
      },
      width: "50px",
    },
    {
      title: "Total Est. Amount",
      dataIndex: "budget",
      render: (budget, record) => {
        const isHourlyJob = record.hauling_type === "Hourly";
        if (isHourlyJob) {
          return (
            <span>
              $ {record.budget}/{unitFromHaulingType(record.hauling_type)}
            </span>
          );
        }
        return (
          <span>
            $ {(record.quantity || record.hourly_minimum) * record.budget}
          </span>
        );
      },
      width: "50px",
    },
    {
      title: "Modified",
      dataIndex: "modified_ts",
      hidden: true,
      render: (modified_ts, record, i) => modified_ts,
      sorter: (a, b) => b.modified_ts - a.modified_ts,
    },
    {
      title: "action",
      dataIndex: "job_status",
      hidden: params.job_status !== "completed",
      render: (job_status, record) => {
        return job_status === "Invoice Generated" ? (
          <Button
            size="small"
            type="primary"
            onClick={() => {
              navigate(
                "/invoice/" +
                  record.bid_id +
                  "/" +
                  record.job_id +
                  "/" +
                  company_id
              );
            }}
          >
            View Invoice
          </Button>
        ) : (
          "-"
        );
      },
    },
  ];

  const [handleAcceptLoading, setHandleAcceptLoading] = useState(false);

  const handleAccept = (rec, action) => {
    setHandleAcceptLoading(true);
    let data = {
      job_id: rec.job_id,
      bid_id: rec.bid_id,
      company_id: business && business.profile.company_id,
      action: action,
    };

    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.CONTRACTOR.JOBS,
      type: "PUT",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          refetchJobs();
          setIsModalVisible(false);
          setSelectedBid(null);
          setIsContractModalVisible(false);
          Toastr.success("Status Updated Successfully!");
        }
        setHandleAcceptLoading(false);
      },
      (err) => {
        Toastr.error("Something went wrong!");
        setHandleAcceptLoading(false);
      }
    );
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { mutate: createJob, isPending: isCreateJobLoading } =
    useCreateJobMutation({
      onSuccess: () => {
        invalidateQueries([Queries.jobs]);
        Toastr.success("Job Created successfully!");
        refetchJobs();
        form.resetFields();
        setModalVisible(false);
      },
    });

  const handleFinish = (values) => {
    let formatMethod = UTIL.formatter("CAPITALIZE");
    let jobType = formatMethod(user.job_type);

    let payload = {
      private: "false",
      form_input: {
        ...values.form_input,
        company_id: business.profile.company_id,
        business_name: business.profile.business_name,
        job_type: jobType,
        hourly_minimum: values.minimum,
      },
    };

    createJob(payload);
  };

  const showPaymentModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      {Object.keys(params).length === 0 && (
        <Row>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="flex gap-4">
              <Button type="primary" onClick={() => setModalVisible(true)}>
                Create New job
              </Button>
            </div>

            <PaymentModal
              visible={isModalVisible}
              isSubmiting={isPaymentLoading}
              onCancel={handleClose}
              onSubmit={() => {
                makePayment(paymentPayload);
              }}
            />
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: "15px" }}>
        <Col span={24}>
          <div className="ant-table-custom-wrapper">
            <Table
              columns={jobs_columns}
              dataSource={props.job}
              rowKey={(record) => record.job_id}
              pagination={
                props.job &&
                props.job.length > 10 && {
                  showSizeChanger: true, // Enable page size options
                  pageSizeOptions: ["10", "15", "20"],
                  pageSize: pageSize,
                  current: activePage,
                  total: props && props.job && props.job.length,
                  onChange: (activePage, pageSize) => {
                    setactivePage(activePage);
                    setPageSize(pageSize);
                  },
                }
              }
              loading={props.loading}
              expandable={{
                expandedRowRender: (record) => {
                  for (const rec of record.bids) {
                    rec.job_id = record.job_id;
                    rec.hauling_type = record.hauling_type;
                    rec.hasContractorPaid = record.hasContractorPaid;
                    rec.hasTruckerPaid = record.hasTruckerPaid;
                    const isHourlyJob = record.hauling_type == "Hourly";
                    rec.isHourlyJob = isHourlyJob;
                    rec.totalAmount = isHourlyJob
                      ? record.estimated_hours * record.budget
                      : record.quantity * record.budget;
                  }
                  return (
                    <div>
                      <div style={{ padding: "0 1em 2em 2em" }}>
                        <Table
                          rowKey={(record) => record.bid_id}
                          columns={bids_columns}
                          dataSource={record.bids}
                          pagination={false}
                        />
                      </div>
                    </div>
                  );
                },
                rowExpandable: (record) =>
                  record.bids ? record.bids.length > 0 : false,
              }}
            />
          </div>
        </Col>
      </Row>

      <CreateJobFormModal
        form={form}
        visible={modalVisible}
        isSubmiting={isCreateJobLoading}
        onSubmit={(data) => {
          handleFinish(data);
        }}
        onClose={() => {
          setModalVisible(false);
        }}
      />

      <ContractForm
        file={biddingContract}
        modalVisible={isContractModalVisible}
        isProcessing={isPaymentLoading || handleAcceptLoading}
        onCancel={() => {
          setSelectedBid(null);
          setIsContractModalVisible(false);
        }}
        onAccept={() => {
          handleAccept(selectedBid, "accept");
        }}
      />

      <Modal
        title="Credit Application"
        open={pdfModalVisible}
        onCancel={() => setPdfModalVisible(false)}
        width={800}
        footer={null}
      >
        <div style={{ height: "70vh", overflowY: "auto" }}>
          {pdfUrl ? (
            <Document
              key={pdfKey}
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              options={{
                cMapUrl: "cmaps/",
                cMapPacked: true,
              }}
            >
              {isPdfLoaded &&
                Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={750}
                  />
                ))}
            </Document>
          ) : (
            <div>No PDF available for this record.</div>
          )}
          {!isPdfLoaded && pdfUrl && <div>Loading PDF...</div>}
          {pdfError && <div style={{ color: "red" }}>{pdfError}</div>}
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="">
            <a href={pdfUrl} target="_blank" download>
              Download Form
            </a>
          </div>
          <div className="">
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => setPdfModalVisible(false)}
            >
              Close
            </Button>
            <Button
              onClick={handlePdfDecline}
              danger
              style={{ marginRight: "10px" }}
              disabled={!isPdfLoaded}
            >
              Decline
            </Button>
            <Button
              onClick={handlePdfAccept}
              type="primary"
              style={{ marginRight: "10px" }}
              disabled={!isPdfLoaded}
            >
              Accept
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Bids;
