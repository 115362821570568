import { QueryClient } from "@tanstack/react-query";
import { notification } from "antd";

const renderErrorItems = (items) => {
  return (
    <div className="">
      <ul style={{ marginTop: "10px" }}>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

const showErrorToast = (err) => {
  const data = err?.response?.data;

  const message = data?.message ?? err?.message ?? data;

  const validationMessages = data?.validationMessages;

  const error =
    typeof message === "string"
      ? message
      : "Something went wrong with the request";

  const isValidationErr =
    data?.error === "ValidationError" || error === "ValidationError";

  const validationErrors = validationMessages
    ? Object.values(validationMessages)?.map((e) => e?.toString())
    : null;

  let title = "Error";
  let description = error;

  if (isValidationErr && validationErrors) {
    title = "Validation Error";

    description = renderErrorItems(validationErrors);
  }
  // Display notification with Ant Design
  // notification.error({
  //   message: title,
  //   description: description || "An unexpected error occurred.",
  //   placement: "topRight", // You can adjust the placement as needed
  //   duration: 5, // Duration in seconds
  // });
};

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error) => {
        showErrorToast(error);
      },
    },
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000 * 5,
    },
  },
});

export const invalidateQueries = async (queries) => {
  await queryClient.invalidateQueries({
    queryKey: queries,
  });
};
