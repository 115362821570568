import { useMutation } from "@tanstack/react-query";
import { pauseHourlyTrip } from "../services/drivers";
import { Mutations } from ".";

const usePauseTrip = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: pauseHourlyTrip,
    mutationKey: [Mutations.pauseTrip],
    onSuccess,
  });
};

export default usePauseTrip;
