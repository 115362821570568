import { Steps, Button, Modal } from "antd";

import CardWrap from "../../components/CardWrapper";
import { useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { BUCKET_URL } from "../../utils/files";
import React from "react";
import ManageProject from "../../components/modules/ManageProject/ManageProject.view";
import Toaster from "toastr";

import moment from "moment";
import useJob from "../../api/queries/useJob";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/primitives/Loader";
import { queryClient } from "../../api/queryClient";
import { Queries } from "../../api/queries";
import useHauls from "../../api/queries/useHauls";
import HaulAssignmentHelper from "../../helpers/haulAssignmentHelper";
import AssignmentHelper from "../../helpers/assignmentHelper";
import DriverTripDropdown from "../../components/primitives/DriverTripDropdown";
import TripInfo from "../../components/primitives/TripInfo";
import ProjectProgressInfo from "../../components/primitives/ProjectProgressInfo";
import useHaulUpdate from "../../api/mutations/useHaulUpdate";
import JobHelper from "../../helpers/jobHelper";
import $sessionStore from "../../store/session";
import Timer from "../../components/primitives/Timer";
import HourlyTripHelper from "../../helpers/hourlyTripHelper";
import { TripStatus } from "../../enums/status";
import { isContractor, isTruckingCompany } from "../../utils/roles";
import { unitFromHaulingType } from "../../utils/units";

dayjs.extend(customParseFormat);

const customDot = () => <div className="dot"></div>;

const getColorByStatus = (status) => {
  const statusColors = {
    Hauling: "#00B336",
    Assigned: "#6F55FF",
    Unassigned: "#DA6944",
  };
  return statusColors[status] || "#000000";
};

//Create HandleJob Compoennt
function ProjectDetails() {
  const { company_id } = $sessionStore.actions.getUser();
  const isRoleTrucker = isTruckingCompany();
  const isRoleContractor = isContractor();
  const { job_id } = useParams();
  const { data: job, isLoading, isFetched } = useJob(job_id);
  const navigate = useNavigate();

  const { mutate: updateHaul, isPending: isUpdating } = useHaulUpdate({
    onSuccess: () => {
      navigate(`/projects/completed`);
    },
  });

  const {
    data: hauls,
    refetch,
    isLoading: isHaulsLoading,
  } = useHauls({
    job_id,
    enabled: isFetched,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false); // State for image modal visibility
  const [imageUrls, setImageUrls] = useState([]); // State to hold image URLs

  const showModal = () => {
    if (jobData.isTransferLimitReached) {
      Toaster.error("Job initiation failed: Load transfer is fully completed.");
      return;
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  dayjs.extend(customParseFormat);

  if (isLoading || isHaulsLoading) {
    return <Loader />;
  }

  const statuses = [
    {
      title: "Unassigned",
    },
    {
      title: "Assigned",
    },
    {
      title: "Hauling",
    },
    {
      title: "Completed",
    },
  ];

  const jobData = new JobHelper(job);

  const isJobCompleted = jobData.isCompleted;
  const unitPrice = parseInt(jobData.bidById(jobData.bid_id).bid_amount || 0);
  const status = jobData.jobStatus;
  const isHourlyJob = jobData.isHourlyJob;

  const isContractorMaterialSource = jobData.isContractorMaterialSource;
  const { firstName = "", lastName = "" } = jobData.companyDetails || {};
  const fullName = `${firstName} ${lastName}`;

  const currentStatusIndex = (status) => {
    return statuses.findIndex((s) => s.title === status);
  };

  const completeProject = () => {
    const payload = {
      job_id,
      method: "completeHaul",
    };
    updateHaul(payload);
  };

  const disabledDates = hauls.map((haul) => {
    return haul.date.split("T")[0];
  });

  const processTypeMap = {
    "on-site-hauling": "On Site Hauling",
    import: "Import",
    export: "Export",
  };

  const showMaterialSourceType = () => {
    let materialSourceType = "";
    if (jobData.isHourlyJob) {
      materialSourceType = "";
    }
    if (jobData.isExportJob) {
      if (jobData.isContractorMaterialSource) {
        materialSourceType = "To location Designated";
      }
      materialSourceType = "Trucker Dispose";
    }
    if (jobData.isImportJob) {
      if (jobData.isContractorMaterialSource) {
        materialSourceType = "Contractor Supplied Material";
      }
      materialSourceType = "Trucker Supplied Material";
    }

    if (!jobData.isHourlyJob) {
      return " - " + materialSourceType;
    }

    return materialSourceType;
  };

  const showImageModal = (tripImages) => {
    setImageUrls(tripImages); // Set the image URLs
    setIsImageModalVisible(true); // Show the modal
  };

  const handleImageModalClose = () => {
    setIsImageModalVisible(false); // Close the modal
    setImageUrls([]); // Clear the image URLs
  };

  return (
    <div className="md:py-4 md:px-24 px-8  space-y-8">
      <div className="flex justify-between">
        <h1 className="text-2xl flex-shrink-0">
          Project: {jobData.projectName}
        </h1>

        <div className="flex items-center gap-4">
          <h1 className="text-2xl flex-shrink-0">Status: </h1>
          <Button
            type="primary"
            color="primary"
            style={{
              backgroundColor: getColorByStatus(status),
              color: "white",
            }}
            shape="round"
            size="large"
            className=" pointer-events-none"
          >
            {status}
          </Button>
        </div>
      </div>

      <Steps
        current={currentStatusIndex(status)}
        labelPlacement="horizontal"
        progressDot={customDot}
        items={statuses}
      />

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-32">
        <div>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-3">
              <CardWrap>
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-md font-semibold">Job Date</div>
                  <div className="text-grey-light">
                    {moment.utc(jobData.job_date_ts).format("MM-DD-YYYY")}
                  </div>
                  <div className="text-md font-semibold">Type of Operation</div>
                  <div className="text-grey-light">
                    {processTypeMap[jobData.processType]} -
                    {jobData.materialSource}
                  </div>
                  <div className="text-md font-semibold">Hauling Type</div>
                  <div className="text-grey-light">{jobData.haulingType}</div>
                  <div className="text-md font-semibold">Material Type</div>
                  <div className="text-grey-light">{jobData.materialType}</div>
                  {!jobData.isHourlyJob && (
                    <React.Fragment>
                      <div className="text-md font-semibold">Quantity</div>
                      <div className="text-grey-light">
                        {jobData.totalMaterial} Tons
                      </div>
                    </React.Fragment>
                  )}

                  <div className="text-md font-semibold">Budget</div>
                  <div className="text-grey-light">
                    $ {jobData.budget}/
                    {unitFromHaulingType(jobData.haulingType)}
                  </div>
                  <div className="text-md font-semibold">Tax Exempt</div>
                  <div className="text-grey-light">
                    {jobData.isTaxExempt ? "Yes" : "No"}
                  </div>
                  <div className="text-md font-semibold">Bid Closing Date</div>
                  <div className="text-grey-light">
                    {moment(jobData.bidClosingDate).format("MM-DD-YYYY")}
                  </div>
                </div>
              </CardWrap>
            </div>
            <div className="col-span-3">
              <CardWrap>
                <h4 className="font-semibold text-md">Contact</h4>
                <div className="">
                  <h3 className="text-grey-light">{fullName || "-"}</h3>
                </div>
                <div className="">
                  <h3 className="text-grey-light">
                    {jobData.companyDetails.phoneNumber || "-"}
                  </h3>
                </div>
              </CardWrap>
            </div>
            <div className="col-span-3">
              <CardWrap>
                <h4 className="font-semibold text-md">Project Notes</h4>
                <div className="">
                  <h3 className="text-grey-light">
                    {jobData.specialInstructions}
                  </h3>
                </div>
              </CardWrap>
            </div>
            <div className="col-span-3">
              <CardWrap>
                <h4 className="font-semibold text-md">Construction Site</h4>
                {jobData.isGpsNotAvailable ? (
                  <h4 className="text-grey-light">
                    GPS Coordinates Not Available
                  </h4>
                ) : (
                  <iframe
                    title="map"
                    width="100%"
                    id="gmap_canvas"
                    className="rounded"
                    src={[
                      "https://maps.google.com/maps?q=",
                      jobData.gps,
                      "&t=&z=13&ie=UTF8&iwloc=&output=embed",
                    ].join("")}
                  ></iframe>
                )}

                <div className="">
                  <h3 className="text-md font-semibold">Address</h3>
                  <h3 className="text-grey-light">{jobData.address.address}</h3>
                </div>
                <div className="">
                  <h3 className="text-md font-semibold">GPS Coordinates</h3>
                  <h3 className="text-grey-light">
                    {jobData.gpsCoordinates.lat}° N,{" "}
                    {jobData.gpsCoordinates.lng}° W
                  </h3>
                </div>
              </CardWrap>
            </div>
            {jobData.isContractorMaterialSource && (
              <div className="col-span-3">
                <CardWrap className="col-span-3">
                  (
                  <React.Fragment>
                    <h4 className="font-semibold text-md">Material Site</h4>
                    <iframe
                      title="map"
                      width="100%"
                      id="gmap_canvas"
                      className="rounded"
                      src={[
                        "https://maps.google.com/maps?q=",
                        jobData.contractorMaterialSourceGps.latitude,
                        ",",
                        jobData.contractorMaterialSourceGps.longitude,
                        "&t=&z=13&ie=UTF8&iwloc=&output=embed",
                      ].join("")}
                    ></iframe>
                    <div className="">
                      <h3 className="text-md font-semibold">Address</h3>
                      <h3 className="text-grey-light">
                        {jobData.isContractorMaterialSource &&
                          jobData.contractorMaterialSourceAddress}
                      </h3>
                    </div>
                    <div className="">
                      <h3 className="text-md font-semibold">GPS Coordinates</h3>
                      <h3 className="text-grey-light">
                        {jobData.contractorMaterialSourceGps.latitude}° N,{" "}
                        {jobData.contractorMaterialSourceGps.longitude}° W
                      </h3>
                    </div>
                  </React.Fragment>
                  )
                </CardWrap>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="space-y-4">
            {!isHourlyJob && (
              <ProjectProgressInfo
                percent={jobData.completedInPercent}
                total={jobData.totalMaterial}
                completed={jobData.completedTons}
              />
            )}

            {isRoleTrucker && (
              <div className="">
                {!jobData.isAssigned && (
                  <Button
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#6F55FF",
                      color: "#FFFFFF",
                    }}
                    onClick={showModal}
                  >
                    Start Project
                  </Button>
                )}
              </div>
            )}

            {jobData.isAssigned &&
              hauls.map((haul, index) => {
                const haulData = new HaulAssignmentHelper(haul);
                const jobHelper = new JobHelper(haulData.job);
                const isHourlyJob = jobHelper.isHourlyJob;
                const tripInfo = {
                  day: hauls.length - index,
                  date: haulData.data.date,
                  tonsCompleted: haulData.tonsCompleted,
                  status: haulData.status,
                };

                let hideInvoice = true;
                let hoursWorked = 0;

                if (isHourlyJob) {
                  haulData.assignments.forEach((e) => {
                    const assignmentHelper = new AssignmentHelper(e);

                    const curTrip = assignmentHelper.getTheCurrentTrip({
                      isHourlyJob,
                    });

                    const hourlyTripHelper = new HourlyTripHelper(curTrip);
                    hoursWorked += hourlyTripHelper.calculateTotalHoursWorked();
                  });
                  if (hoursWorked > 0) {
                    hideInvoice = false;
                  }
                  if (haulData.status.toLowerCase() === TripStatus.COMPLETED) {
                    hideInvoice = true;
                  }
                } else {
                  hideInvoice = haulData.tonsCompleted > 0;
                  if (haulData.status.toLowerCase() !== TripStatus.COMPLETED) {
                    hideInvoice = false;
                  }
                }

                return (
                  <TripInfo
                    {...tripInfo}
                    gridCols={1}
                    hideTonsComplete={isHourlyJob}
                    hideInvoiceButton={isRoleContractor || hideInvoice}
                    onClickInvoice={() => {
                      const tonsCompleted = haulData.tonsCompleted;
                      const totalQuantity = haulData.job.quantity;
                      const totalPrice = unitPrice * tonsCompleted;
                      const totalHourlyPrice = unitPrice * hoursWorked;

                      const invoices = [
                        {
                          product: jobData.materialType,
                          hoursCompleted: hoursWorked,
                          totalQuantity: totalQuantity,
                          tonsCompleted: tonsCompleted,
                          total: isHourlyJob ? totalHourlyPrice : totalPrice,
                          unitPrice: unitPrice,
                        },
                      ];

                      navigate(
                        "/invoice/" +
                          jobData.bid_id +
                          "/" +
                          job_id +
                          "/" +
                          company_id,
                        {
                          state: {
                            isPartialInvoice: true,
                            invoices,
                            isHourlyJob,
                            invoiceHaulId: haulData.haulId,
                            hourlyJob: !isHourlyJob
                              ? null
                              : {
                                  hoursWorked,
                                  hourlyRate: unitPrice,
                                  invoices,
                                },
                            tonsCompleted: tonsCompleted,
                            material: jobData.materialType,
                            unitPrice: unitPrice,
                            totalPrice: totalPrice,
                            totalQuantity: totalQuantity,
                            haulIds: [haulData.haulId],
                          },
                        }
                      );
                    }}
                  >
                    {isHourlyJob &&
                      haulData.assignments.map((assignment) => {
                        const assignmentHelper = new AssignmentHelper(
                          assignment
                        );

                        const curTrip = assignmentHelper.getTheCurrentTrip({
                          isHourlyJob,
                        });

                        const hourlyTripHelper = new HourlyTripHelper(curTrip);

                        return (
                          <div className="flex items-center gap-4 border rounded-md px-4 py-2">
                            <span className="text-lg font-semibold capitalize">
                              {assignmentHelper.driverName}
                            </span>
                            <Timer
                              timerSize="sm"
                              initialSeconds={hourlyTripHelper.calculateEffectiveTripTime()}
                              pause={true}
                              className="text-lg font-semibold"
                            />
                            <span className="">
                              {hourlyTripHelper.calculateTotalHoursWorked()} Hrs
                            </span>

                            <span className="">
                              ${" "}
                              {(
                                hourlyTripHelper.calculateTotalHoursWorked() *
                                unitPrice
                              ).toFixed(2)}
                            </span>
                          </div>
                        );
                      })}

                    {!isHourlyJob &&
                      haulData.assignments.map((assignment) => {
                        const assignmentHelper = new AssignmentHelper(
                          assignment
                        );

                        return (
                          <DriverTripDropdown
                            titleOne={assignmentHelper.driverName}
                            tonsCompleted={assignmentHelper.totalLoadCompleted}
                          >
                            {assignmentHelper.trips.map((trip, index) => {
                              let images = [];

                              if (trip.source_proof) {
                                images.push(trip.source_proof);
                              }

                              if (trip.destination_proof) {
                                images.push(trip.destination_proof);
                              }

                              return (
                                <div className="border p-2 rounded-md mb-2 flex space-x-2">
                                  <span className="">Trip {index + 1}</span>
                                  <span className="">|</span>
                                  <span className="">
                                    Tons Completed : {trip.load_quantity}
                                  </span>
                                  <span className="">|</span>

                                  <a
                                    className=""
                                    onClick={() => showImageModal(images)}
                                  >
                                    View Images
                                  </a>
                                </div>
                              );
                            })}
                          </DriverTripDropdown>
                        );
                      })}
                  </TripInfo>
                );
              })}

            {isRoleTrucker && (
              <div className="">
                <div className="">
                  {isJobCompleted == true ? (
                    <div className="flex justify-center gap-4 mt-24">
                      <div className="flex gap-4">
                        <div
                          disabled
                          className="rounded-[5px] bg-[#6F55FF] text-white px-2 py-1"
                        >
                          Job Complete
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      {jobData.isAssigned && (
                        <div className="flex justify-center gap-4 mt-24">
                          <Button
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#6F55FF",
                              color: "#FFFFFF",
                            }}
                            onClick={showModal}
                          >
                            Start New Day
                          </Button>
                          <Button
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#6F55FF",
                              color: "#FFFFFF",
                            }}
                            loading={isUpdating}
                            onClick={completeProject}
                          >
                            Complete Project
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <Modal
                  centered
                  open={isModalOpen}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <ManageProject
                    disabledDates={disabledDates}
                    isContractorMaterialSource={isContractorMaterialSource}
                    isHourlyJob={jobData.isHourlyJob}
                    onSuccess={() => {
                      queryClient.invalidateQueries({
                        queryKey: [Queries.job],
                      });
                      window.location.reload();
                      setIsModalOpen(false);
                    }}
                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal for displaying images */}
      <Modal
        title="Trip Images"
        visible={isImageModalVisible}
        onCancel={handleImageModalClose}
        footer={null}
        style={{ overflowY: "auto", maxHeight: "80vh" }}
      >
        <div className="flex flex-wrap">
          {imageUrls.length === 0 && (
            <div className="text-center text-gray-600">Images not uploaded</div>
          )}
          {imageUrls.map((url, index) => (
            <div className="">
              <img
                key={index}
                src={`${process.env.REACT_APP_BUCKET_URL}${url}`}
                alt={`trip-${index + 1}`}
                className="w-full h-auto mb-2"
              />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

export default ProjectDetails;
