import * as Yup from "yup";

//create enums for driver status

export const entryStatus = {
  ADDED: "ADDED",
  FINALIZED: "FINALIZED",
  UNASSIGNED: "UNASSIGNED",
};

export const generateInitialData = (trucks, drivers) => {
  const haulers = trucks.map((truck) => {
    return {
      ...truck,
      status: entryStatus.UNASSIGNED,
      driver: null,
      quarry_id: null,
      mine_id: null,
    };
  });
  drivers = drivers.map((driver) => {
    return {
      ...driver,
      status: entryStatus.UNASSIGNED,
    };
  });

  return {
    date: null,
    haulers: haulers,
    drivers: drivers,
  };
};

export const startDateValidation = Yup.object().shape({
  date: Yup.date().required("Start Date is required"),
});

export const truckSelectValidation = Yup.object().shape({
  haulers: Yup.array()
    .required("Truck is required")
    .test("check-truck-selected", "Please select Truck", (haulers) => {
      const nonFinalizedTrucks = haulers.filter(
        (hauler) => hauler?.status !== entryStatus.FINALIZED
      );
      return nonFinalizedTrucks.find(
        (hauler) => hauler?.status === entryStatus.ADDED
      );
    }),
});

export const driverSelectValidation = Yup.object().shape({
  drivers: Yup.array()
    .required("Driver is required")
    .test("check-driver-selected", "Please select Driver", (drivers) => {
      const nonFinalizedDrivers = drivers.filter(
        (hauler) => hauler?.status !== entryStatus.FINALIZED
      );
      return nonFinalizedDrivers.find(
        (hauler) => hauler?.status === entryStatus.ADDED
      );
    }),
});

export const quaryAndMineSelectionValidation = Yup.object().shape({
  haulers: Yup.array()
    .of(
      Yup.object().shape({
        status: Yup.string().required(),
        quarry_id: Yup.string().when("status", {
          is: entryStatus.ADDED,
          then: (schema) => schema.required("Quarry is Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        mine_id: Yup.string().when("status", {
          is: entryStatus.ADDED,
          then: (schema) => schema.required("Mine is Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      })
    )
    .required("Truck is required")
    .min(1, "At least one hauler must be selected"),
});
