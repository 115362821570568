import { Button } from "antd";
import useRequestPaymentSessionMutation from "./mutations";
import toastr from "toastr";

const ElavonPaymentButton = ({
  onSuccess,
  onError,
  onCancelled,
  onDeclined,
  title = "Pay",
  style,
  payload,
} = {}) => {
  const callback = {
    onError: function (error) {
      toastr.error(error);
      onError && onError(error);
    },
    onCancelled: function () {
      toastr.error("Payment Cancelled");
      onCancelled && onCancelled();
    },
    onDeclined: function (response) {
      toastr.error("Payment Declined");
      onDeclined && onDeclined(response);
    },
    onApproval: function (response) {
      toastr.success("Payment Successful");
      onSuccess && onSuccess(response);
    },
  };

  const { mutate: requestPaymentSessionMutation, isPending: isPaymentLoading } =
    useRequestPaymentSessionMutation({
      onSuccess: (data) => {
        var paymentFields = {
          ssl_txn_auth_token: data,
        };
        initiatePayment(paymentFields, callback);
      },
    });

  const initiatePayment = (paymentFields, callback) => {
    if (window.PayWithConverge) {
      window.PayWithConverge.open(paymentFields, callback);
    } else {
      console.error("PayWithConverge.js is not loaded");
    }
  };

  return (
    <Button
      style={style}
      loading={isPaymentLoading}
      onClick={() => {
        requestPaymentSessionMutation(payload);
      }}
    >
      {title}
    </Button>
  );
};

export default ElavonPaymentButton;
