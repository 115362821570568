import { useMutation } from "@tanstack/react-query";
import { makePayment } from "../services/jobs";
import { Mutations } from ".";

const useMakePayment = ({ onSuccess } = {}) => {
  return useMutation({
    mutationKey: [Mutations.makePayment],
    mutationFn: makePayment,
    onSuccess,
  });
};

export default useMakePayment;
