import { useMutation } from "@tanstack/react-query";
import { resumeHourlyTrip } from "../services/drivers";
import { Mutations } from ".";

const useResumeTrip = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: resumeHourlyTrip,
    mutationKey: [Mutations.resumeTrip],
    onSuccess,
  });
};

export default useResumeTrip;
