import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
} from "antd";
import SubmitButton from "../../components/Button";
import { AiOutlineDelete } from "react-icons/ai";
import TextArea from "antd/es/input/TextArea";
import { API } from "../../config/AppConstants";
import $ from "jquery";
import Toastr from "toastr";
import useSession from "../../context/SessionContext";
import { FaLocationDot } from "react-icons/fa6";
import MapPicker from "../../components/MapPicker";
import AddressSelectionInput from "../../components/modules/AddressSelectionInput/AddressSelectionInput.view";

const Mines = () => {
  const { user } = useSession();
  const [form] = Form.useForm();
  const [mineInfo, setMineInfo] = useState([]);
  const [gps, setGps] = useState("");
  const [mapModalVisible, setMapModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [options, setOptions] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [save, setSave] = useState(false);
  const [update, setUpdate] = useState(false);

  const handleLocationCoordinated = (value) => {
    let lat_lng_val =
      value && value.lat.toFixed(2) + "," + value.lng.toFixed(2);
    form.setFieldsValue({ gps: lat_lng_val });
    setGps(lat_lng_val);
    setMapModalVisible(false);
  };

  useEffect(() => {
    if (user) {
      fetchMinesInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const quarry_columns = [
    {
      title: "Mine Name",
      dataIndex: "mine_name",
      render: (mine_name, record, i) => {
        return <span className="anchor_link">{mine_name}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
      onCell: (record, recordIndex) => ({
        onClick: () => onCellSelected(record),
      }),
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (product, record, i) => {
        return <span>{product}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (address, record, i) => {
        return <span>{address}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "City",
      dataIndex: "city",
      render: (city, record, i) => {
        return <span>{city}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "State",
      dataIndex: "mine_state",
      render: (mine_state, record, i) => {
        return <span>{mine_state}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Zip Code",
      dataIndex: "zipcode",
      render: (zipcode, record, i) => {
        return <span>{zipcode}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
  ];

  const onCellSelected = (record) => {
    setSelectedRow(record);
    form.setFieldsValue(record);
    setModalVisible(true);
  };

  const onFinish = (values) => {
    if (selectedRow) {
      updateMine(values);
    } else {
      saveMine(values);
    }
  };

  let updateMine = (values) => {
    setUpdate(true);
    let modifiedFields = getModifiedFields(selectedRow, values);
    let form_input = { mine_id: selectedRow._id };
    for (const val in modifiedFields) {
      form_input[val] = modifiedFields[val];
    }
    let data = { form_input: form_input };

    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.MINE.MINE_UPDATE,
      type: "PUT",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          Toastr.success("Mine Updated successfully!");
          form.resetFields();
          setModalVisible(false);
          fetchMinesInfo();
          setSelectedRow(null);
          setUpdate(false);
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
        setUpdate(false);
      }
    );
  };

  let saveMine = (values) => {
    setSave(true);
    let data = {
      form_input: {
        company_id: user.company_id,
        user_login: user.user_login,
        user_email: user.email,
        mine_name: values.mine_name,
        address: values.address,
        gps: values.gps,
        city: "",
        mine_state: "",
        zipcode: "",
        product: values.product,
      },
    };
    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.MINE.MINE,
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify(data),
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    }).then(
      (res) => {
        if (res) {
          Toastr.success("Mine created successfully!");
          form.resetFields();
          setModalVisible(false);
          fetchMinesInfo();
          setSave(false);
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
        setSave(false);
      }
    );
  };

  const fetchMinesInfo = () => {
    setLoading(true);
    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.MINE.MINE +
        "?company_id=" +
        user.company_id +
        "&useGet=0",
      type: "GET",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    }).then(
      (res) => {
        if (res) {
          setMineInfo(res);
          setLoading(false);
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
        setLoading(false);
      }
    );
  };

  const onSelectChange = (newSelectedRowKeys, usersToDelete) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showPagination = mineInfo && mineInfo.length > 10;

  const validateZipCode = (_, value) => {
    let zipPattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (value && !zipPattern.test(value)) {
      return Promise.reject("Enter Valid Zip Code");
    }
    return Promise.resolve();
  };

  const addMine = () => {
    form.setFieldsValue({
      mine_name: "",
      city: "",
      zipcode: "",
      mine_state: "",
      product: "",
      address: "",
    });
    setModalVisible(true);
  };

  const getModifiedFields = (obj1, obj2) => {
    const differingValues = {};
    for (let key in obj1) {
      if (obj2.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        differingValues[key] = obj2[key];
      }
    }
    return differingValues;
  };

  const products = [
    { label: "Sand", value: "Sand" },
    { label: "Modified Subbase", value: "Modified Subbase" },
    { label: "Special Backfill", value: "Special Backfill" },
    { label: "Rip Rap", value: "Rip Rap" },
    { label: "Pea Gravel", value: "Pea Gravel" },
    { label: "Clean Stone", value: "Clean Stone" },
    { label: "Crushed Concrete", value: "Crushed Concrete" },
    { label: "Other", value: "Other" },
  ];

  const deleteMine = (mine) => {
    return new Promise((resolve, reject) => {
      let data = {
        form_input: {
          mine_id: mine,
        },
      };
      $.ajax({
        url: API.API_URL + API.API_VER + API.API_ENDPOINTS.MINE.MINE,
        type: "DELETE",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        data: JSON.stringify(data),
      }).then(
        (res, textStatus, xhr) => {
          if (xhr.status === 200) {
            resolve("mine deleted successfully!");
          }
        },
        (err) => {
          if (err.status === 200) {
            resolve("mine deleted successfully!");
          }
          resolve("Something went wrong!");
        }
      );
    });
  };

  const handleDelete = async () => {
    for (const mine_id of selectedRowKeys) {
      await deleteMine(mine_id);
    }
    Toastr.success("Mine deleted successfully!");
    fetchMinesInfo();
    setSelectedRowKeys([]);
  };

  return (
    <React.Fragment>
      <Row>
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" onClick={() => addMine()}>
            Add Mine
          </Button>
          <Popconfirm
            title="Delete Mine"
            description={
              selectedRowKeys.length > 1
                ? "Are you sure to delete these Mines?"
                : "Are you sure to delete this Mine?"
            }
            okText="Yes"
            cancelText="No"
            onConfirm={handleDelete}
          >
            <Button
              type="primary"
              style={{ marginLeft: "6px" }}
              disabled={!selectedRowKeys.length > 0}
              danger
              icon={<AiOutlineDelete size={20} />}
            ></Button>
          </Popconfirm>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <Col span={24}>
          <div className="ant-table-custom-wrapper">
            <Table
              columns={quarry_columns}
              dataSource={mineInfo}
              rowSelection={rowSelection}
              pagination={showPagination ? {} : false}
              rowKey={(record) => record._id}
              loading={loading}
            />
          </div>
        </Col>
      </Row>
      <Modal
        title={selectedRow ? "Edit Mine" : "Add Mine"}
        open={modalVisible}
        maskClosable={false}
        width={600}
        onCancel={() => {
          setModalVisible(false);
          setSelectedRow(null);
        }}
        style={{ top: 60 }}
        destroyOnClose={true}
        footer={false}
        zIndex={1}
      >
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            maxWidth: "100%",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="nest-messages"
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 22 }}
            style={{ maxWidth: 700, margin: "15px 30px" }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"mine_name"}
                  label={"Name"}
                  rules={[
                    { required: true, message: `Mine Name is required!` },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"product"}
                  label={"Product"}
                  rules={[{ required: true, message: `Product is required!` }]}
                >
                  <Select
                    options={products}
                    values={options}
                    onChange={(selected) => {
                      setOptions(selected);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <AddressSelectionInput form={form} />
              </Col>
            </Row>

            <Col
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "15px",
                marginRight: "18px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
              <SubmitButton form={form}>
                {selectedRow
                  ? update
                    ? "Updating..."
                    : "Update"
                  : save
                  ? "Saving..."
                  : "Save"}
              </SubmitButton>
            </Col>
          </Form>
        </div>
      </Modal>
      <Modal
        title={"Select Location"}
        open={mapModalVisible}
        maskClosable={false}
        width={800}
        onCancel={() => {
          setMapModalVisible(false);
          form.resetFields();
        }}
        style={{ top: 60 }}
        footer={true}
        zIndex={10}
      >
        <MapPicker setLocation={handleLocationCoordinated} />
      </Modal>
    </React.Fragment>
  );
};

export default Mines;
