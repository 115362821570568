import { useMutation } from "@tanstack/react-query";
import GoogleApiHelper from "../services/googlePlaces";

const googleApiHelper = new GoogleApiHelper();

export const useGetAddressFromLatLng = ({ onSuccess } = {}) => {
  return useMutation({
    mutationKey: ["getAddressFromLatLng"],
    mutationFn: ({ lat, lng }) =>
      googleApiHelper.getAddressFromLatLng(lat, lng),
    onSuccess,
  });
};

export const useGetCoordinatesFromAddress = ({ onSuccess } = {}) => {
  return useMutation({
    mutationKey: ["getCoordinatesFromAddress"],
    mutationFn: ({ address }) =>
      googleApiHelper.getCoordinatesFromAddress(address),
    onSuccess,
  });
};

export const useGetAutocompleteSuggestions = () => {
  return useMutation({
    mutationKey: ["getAutocompleteSuggestions"],
    mutationFn: ({ input }) =>
      googleApiHelper.getAutocompleteSuggestions(input),
  });
};
