class DateTimeHelper {
  static adjustDateToTimezone(utcDateString) {
    // Parse the UTC date string
    const utcDate = new Date(utcDateString);

    // Get the local timezone offset in minutes (converting to hours)
    const timezoneOffsetInMinutes = utcDate.getTimezoneOffset();

    // Convert the offset to milliseconds and subtract it from the UTC date
    const adjustedTime =
      utcDate.getTime() - timezoneOffsetInMinutes * 60 * 1000;

    // Create a new date with the adjusted time
    const localDate = new Date(adjustedTime);

    // Return the ISO string with hours adjusted for the local timezone
    return localDate.toISOString();
  }

  static convertCurrentDateToUTC() {
    const currentDate = new Date();
    const utcDate = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds()
    );
    return utcDate.toUTCString();
  }

  static getCurrentUTCISOstring() {
    return new Date(new Date().toUTCString()).toISOString();
  }

  static getTimezoneOffset() {
    const offset = new Date().getTimezoneOffset();
    return offset;
  }
}

export default DateTimeHelper;
