import $sessionStore from "../store/session";

const isDriver = () => {
  const user = $sessionStore.actions.getUser();
  return user && user.role_id === "R013" && user.role_type === "R003";
};

const isTruckingCompany = () => {
  const user = $sessionStore.actions.getUser();
  return user && user.role_id === "R003" && user.role_type === "global";
};

const isContractor = () => {
  const user = $sessionStore.actions.getUser();
  return user && user.role_id === "R001" && user.role_type === "global";
};

export { isDriver, isTruckingCompany, isContractor };
