import { Calendar } from "antd";
import { useFormikContext } from "formik";
import React from "react";
import FormikErrorMessage from "../../primitives/FormikErrorMessage";

const SelectStartDateFormPhase = () => {
  const form = useFormikContext();
  const disabledDates = new Set(form.values.disabledDates);

  return (
    <React.Fragment>
      <Calendar
        disabledDate={(date) => {
          if (date.endOf("d").valueOf() < Date.now()) {
            return true;
          }
          const dateString = new Date(date).toISOString().split("T")[0];

          if (disabledDates.has(dateString)) {
            return true;
          }

          return false;
        }}
        fullscreen={false}
        value={form.values.date}
        onChange={(value) => {
          form.setFieldValue("date", value);
        }}
      />
      <FormikErrorMessage name={"date"} />
    </React.Fragment>
  );
};

export default SelectStartDateFormPhase;
