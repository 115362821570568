import ElavonPaymentButton from "../components/core/ElavonPaymentButton/ElavonPaymentButton";

const PayTest = () => {
  return (
    <div>
      <ElavonPaymentButton />
    </div>
  );
};

export default PayTest;
