import { useMutation } from "@tanstack/react-query";
import { createJob } from "../services/jobs";
import { Mutations } from ".";

const useCreateJobMutation = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: createJob,
    mutationKey: [Mutations.createJob],
    onSuccess,
  });
};

export default useCreateJobMutation;
