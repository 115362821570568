import { useMutation } from "@tanstack/react-query";
import { submitInvoice } from "../services/invoice";

const useSubmitInvoiceMutation = ({ options }) =>
  useMutation({
    mutationKey: ["submit-invoice"],
    mutationFn: submitInvoice,
    ...options,
  });

export default useSubmitInvoiceMutation;
