import { useParams } from "react-router-dom";

import useHauls from "../../api/queries/useHauls";
import Loader from "../../components/primitives/Loader";
import DriverProjectDetails from "./DriverProjectDetails.view";

const DriverProjectDetailsContainer = () => {
  const { assignment_id } = useParams();
  const { data: hauls, isLoading: isHaulsLoading } = useHauls({
    assignment_id,
  });

  if (!hauls && isHaulsLoading) {
    return <Loader />;
  }

  return <DriverProjectDetails hauls={hauls} />;
};

export default DriverProjectDetailsContainer;
