import { useMutation } from "@tanstack/react-query";
import { initiateHourlyTrip } from "../services/drivers";
import { Mutations } from ".";

const useInitiateHourlyTrip = ({ onSuccess }) => {
  return useMutation({
    mutationFn: initiateHourlyTrip,
    mutationKey: [Mutations.initiateHourlyTrip],
    onSuccess,
  });
};

export default useInitiateHourlyTrip;
