import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Table,
  Card,
  Descriptions,
  Form,
  Input,
  Tag,
  Popconfirm,
  Typography,
} from "antd";
import { API } from "../../config/AppConstants";
import $ from "jquery";
import useSession from "../../context/SessionContext";
import Toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import UTIL from "./../../Util";
import Bids from "../contractor/bids";
import biddingContract from "../../assets/docs/bidding-contract.pdf";
import moment from "moment";
import ContractForm from "../../components/modules/ContractForm/ContractForm.view";
import JobHelper from "../../helpers/jobHelper";

const Jobs = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentSelectedJob, setCurrentSelectedJob] = useState([]);
  const isOnSiteHauling =
    currentSelectedJob && currentSelectedJob.process_type === "on-site-hauling";

  const [bidAmount, setBidAmount] = useState(null);
  const [jobStatus, setJobStatus] = useState("Pending");
  let { user, business, setUser } = useSession();
  const [form] = Form.useForm();
  let [jobs, setJobs] = useState([]);
  const currentDate = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(currentDate.getDate() - 10);

  const jobHelper = new JobHelper(currentSelectedJob);

  const isTruckerSuppliedMaterial = jobHelper.isTruckerSuppliedMaterial;

  const enableMaterialPriceInput =
    !isOnSiteHauling && isTruckerSuppliedMaterial;

  const timestampTenDaysAgo = tenDaysAgo.getTime();
  const [isContractModalVisible, setIsContractModalVisible] = useState(false);
  const tagColors = {
    Bidded: "#e89a3c",
    InProgress: "#e774bb",
    Available: "#4c9c33",
    Billed: "gold",
    Contracted: "#2db1a8",
    ContractorDeclined: "#9a3615",
    Declined: "#9a3615",
    Completed: "#4c9c33",
  };

  const selectJobType = (job_type) => {
    let userObj = { ...user };
    userObj["job_type"] = job_type;
    setUser(userObj);
  };

  useEffect(() => {
    selectJobType("now");
  }, []);

  useEffect(() => {
    setOpen(false);
    let formatMethod = UTIL.formatter("CAPITALIZE");
    let jobStatus = formatMethod(params.job_status || "Pending");
    jobStatus = jobStatus === "Inprogress" ? "InProgress" : jobStatus;
    setJobStatus(jobStatus);

    if (
      (user &&
        user.role_id === "R003" &&
        (jobStatus === "Pending" || jobStatus === "Bidded")) ||
      (user && user.role_id === "R001" && jobStatus === "Completed")
    ) {
      callGetJobs(jobStatus);
    } else {
      getJobs(jobStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.job_status, user && user.job_type]);

  const callGetJobs = async (job_status) => {
    let jobStatus;

    switch (job_status) {
      case "Pending":
        jobStatus = ["Pending", "Declined"];
        break;
      case "Bidded":
        jobStatus = ["Bidded", "ContractorDeclined"];
        break;
      case "Billed":
        jobStatus = ["Invoice Generated"];
        break;
      case "Completed":
        jobStatus = ["Billed"];
        break;
      default:
        jobStatus = ["Completed", "Billed"];
        break;
    }
    let jobs = [];
    for (const status of jobStatus) {
      let result = await getJobs(status);
      if (!result) {
        continue;
      }
      for (const item of result) {
        if (status === "Bidded") {
          if (item.bid && item.bid.contractor_choice !== "ContractorDeclined") {
            jobs.push(item);
          }
          continue;
        }
        jobs.push(item);
      }
    }
    let filtertedJobs = [];
    for (const job of jobs) {
      if (job.job_type === (user && user.job_type)) {
        filtertedJobs.push(job);
      }
    }
    setJobs(jobs);
  };

  const jobDrawer = (rec) => {
    if (rec.bid) {
      form.setFieldsValue({ bid_amount: rec.bid.bid_amount });
    } else {
      form.setFieldsValue({ bid_amount: "" });
    }
    setOpen(true);
    setCurrentSelectedJob(rec);
  };

  const onClose = () => {
    setOpen(false);
    setCurrentSelectedJob([]);
  };

  const getTimestampTitle = () => {
    let title = "";
    switch (jobStatus) {
      case "Contracted":
        title = "Job Time";
        break;
      case "In Progress":
        title = "Job Start Time";
        break;
      case "Completed":
        title = "Job complete Time";
        break;
      default:
        title = "Bid Start Time";
        break;
    }
    return title;
  };

  const table_columns = [
    {
      title: "Job ID",
      dataIndex: "sequence",
      render: (job_id) => {
        return <span className="anchor_link"> {job_id} </span>;
      },
      fixed: "left",
      width: "10px",
      onCell: (record, job) => ({
        onClick: () => {
          jobDrawer(record);
        },
      }),
    },
    {
      title: "Status",
      dataIndex: "bid",
      hidden: jobStatus !== "Pending" && jobStatus !== "Bidded",
      render: (bid) => {
        return (
          <Tag
            style={{
              cursor: "pointer",
            }}
            color={
              bid &&
              bid.contractor_choice &&
              bid.contractor_choice === "ContractorDeclined"
                ? tagColors[bid.contractor_choice]
                : bid && bid.trucker_choice && bid.trucker_choice
                ? tagColors[bid.trucker_choice]
                : tagColors["Available"]
            }
          >
            {bid &&
            bid.contractor_choice &&
            bid.contractor_choice === "ContractorDeclined"
              ? "Contractor Declined"
              : bid && bid.trucker_choice && bid.trucker_choice === "Declined"
              ? "Self Declined"
              : bid
              ? "Bid Sent"
              : "Available"}
          </Tag>
        );
      },
      width: "10px",
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      render: (project_name) => {
        return <span>{project_name}</span>;
      },
      width: "100px",
    },
    {
      title: "Business Name",
      dataIndex: "business_name",
      render: (business_name) => {
        return <span>{business_name}</span>;
      },
      width: "50px",
    },
    {
      title: getTimestampTitle(),
      dataIndex: "created_ts",
      render: (created_ts, record) => {
        let content;
        switch (jobStatus) {
          case "Pending":
            content = new Date(created_ts).toLocaleString();
            break;
          case "Contracted":
            content = new Date(record.job_date_ts).toLocaleString();
            break;
          default:
            content = new Date(record.modified_ts).toLocaleString();
            break;
        }
        return <span>{content}</span>;
      },
      width: "50px",
    },
    {
      title: "Bid Close Time",
      dataIndex: "bid_closing_date_ts",
      render: (bid_closing_date_ts) => {
        return <span>{new Date(bid_closing_date_ts).toLocaleString()}</span>;
      },
      width: "50px",
      defaultSortOrder: "ascend",
      showSorterTooltip: false,
      sorter: (a, b) => b.bid_closing_date_ts - a.bid_closing_date_ts,
    },
    {
      title: "Est. Qty",
      dataIndex: "quantity",
      render: (budget, record) => {
        return <span>{record.quantity || record.hourly_minimum}</span>;
      },
      width: "50px",
    },
    {
      title: "Bid Amount",
      hidden: (user && user.role_id !== "R003") || jobStatus === "Pending",
      dataIndex: "budget",
      render: (budget, record) => {
        return (
          record.bid && (
            <span>
              {record.bid && record.bid.bid_amount && "$"}{" "}
              {record.bid && record.bid.bid_amount} /{" "}
              {record.hauling_type === "Hourly" ? "Hr" : record.hauling_type}
            </span>
          )
        );
      },
      width: "50px",
    },
    {
      title: "Total Est. Amount",
      hidden: user && user.role_id === "R001",
      dataIndex: "budget",
      render: (budget, record) => {
        return (
          <span>
            $ {(record.quantity || record.hourly_minimum) * record.budget}
          </span>
        );
      },
      width: "50px",
    },
    {
      title: "Actions",
      hidden: ["Pending", "Bidded"].includes(jobStatus),
      render: (record) => {
        let content;
        let bid_id = record && record.bid && record.bid.bid_id;
        let job_id = record && record.job_id;
        let company_id = record && record.company_id;
        switch (jobStatus) {
          case "Contracted":
            content = (
              <Button
                size="small"
                type="primary"
                onClick={() => updateJobStatus(record, "Dispatch")}
              >
                Dispatch
              </Button>
            );
            break;

          case "Billed":
            content = <div className=""></div>;
            break;

          case "Dispatch":
            content = (
              <Button
                size="small"
                type="primary"
                onClick={() => updateJobStatus(record, "InProgress")}
              >
                Start Job
              </Button>
            );
            break;
          case "InProgress":
            content = (
              <Button
                size="small"
                type="primary"
                onClick={() => updateJobStatus(record, "Completed")}
              >
                Complete Job
              </Button>
            );
            break;

          default:
            content = (
              <Button size="small" type="primary">
                <Typography.Link
                  onClick={() =>
                    navigate(
                      "/invoice/" + bid_id + "/" + job_id + "/" + company_id,
                      {
                        state: {
                          material: record.material,
                        },
                      }
                    )
                  }
                >
                  Generate Invoice
                </Typography.Link>
              </Button>
            );
            break;
        }
        return <div>{content}</div>;
      },
      width: "50px",
    },
  ];

  const processTypeMap = {
    "on-site-hauling": "On Site Hauling",
    import: "Import",
    export: "Export",
  };

  const showMaterialSourceType = () => {
    const jobData = new JobHelper(currentSelectedJob);
    let materialSourceType = "";

    if (jobData.isHourlyJob) {
      materialSourceType = "";
    }

    if (jobData.isExportJob) {
      if (jobData.isContractorMaterialSource) {
        materialSourceType = "To location Designated";
      }
      materialSourceType = "Trucker Dispose";
    }

    if (jobData.isImportJob) {
      if (jobData.isContractorMaterialSource) {
        materialSourceType = "Contractor Supplied Material";
      }
      materialSourceType = "Trucker Supplied Material";
    }

    if (!jobData.isHourlyJob) {
      return " - " + materialSourceType;
    }

    return materialSourceType;
  };

  const details = currentSelectedJob && [
    {
      key: 1,
      field: "business_name",
      label: "Contractor",
      children: <span>{currentSelectedJob.business_name}</span>,
    },
    {
      key: 7,
      label: "Date Bids are due",
      children: (
        <span>
          {new Date(currentSelectedJob.bid_closing_date_ts).toLocaleString()}
        </span>
      ),
    },
    {
      key: 2,
      field: "gps",
      children: (
        <iframe
          title={currentSelectedJob.job_id}
          width="100%"
          id="gmap_canvas"
          src={[
            "https://maps.google.com/maps?q=",
            currentSelectedJob.gps,
            "&t=&z=13&ie=UTF8&iwloc=&output=embed",
          ].join("")}
        ></iframe>
      ),
    },

    {
      key: 2,
      field: "address",
      label: "Address",
      children: (
        <span>
          {currentSelectedJob.address}, {currentSelectedJob.city},{" "}
          {currentSelectedJob.state}, {currentSelectedJob.zipcode}
        </span>
      ),
    },
    {
      key: 7,
      label: "Date Work Happens",
      children: (
        <span>{new Date(currentSelectedJob.job_date_ts).toLocaleString()}</span>
      ),
    },
    {
      key: 4,
      label: "Material",
      children: currentSelectedJob.material,
    },

    {
      key: 4,
      label: "Material Source",
      children: jobHelper.materialSource,
    },
    {
      key: 4,
      label: "Job Type",
      children: processTypeMap[currentSelectedJob.process_type],
    },
    {
      key: 3,
      field: "budget",
      label: "Est.Amount / Unit",
      children: <span>${currentSelectedJob.budget}</span>,
    },
    {
      key: 5,
      label: "Hauling Type or Unit",
      children: currentSelectedJob.hauling_type,
    },
    {
      key: 6,
      label: "Estimated Quantity",
      children: currentSelectedJob.quantity
        ? currentSelectedJob.quantity
        : currentSelectedJob.hourly_minimum,
    },
    {
      key: 8,
      field: "special_instructions",
      label: "Special Instructions",
      children: currentSelectedJob.special_instructions,
    },
  ];

  let filteredDetails = [];
  if (currentSelectedJob) {
    for (let k of details) {
      if (k.field) {
        let showDescription =
          currentSelectedJob && currentSelectedJob[k.field] ? true : false;
        showDescription &&
          filteredDetails.push({
            key: k.key,
            label: k.label,
            children: k.children,
          });
      } else {
        filteredDetails.push({
          key: k.key,
          label: k.label,
          children: k.children,
        });
      }
    }
  }

  const getJobs = (job_status) => {
    if (!user || !business) return;

    const user_job_type = user && user.job_type;

    const todaysDateString = moment().format("YYYY-MM-DD");
    setLoading(true);
    setJobs([]);
    let apiEndPoint =
      user && user.role_id === "R001"
        ? API.API_ENDPOINTS.CONTRACTOR.JOBS
        : API.API_ENDPOINTS.TRUCKER.BIDS;
    job_status =
      job_status === "Bidded" && user && user.role_id === "R001"
        ? "Pending"
        : job_status;

    let api_url =
      user && user.role_id === "R001" && Object.keys(params).length === 0
        ? API.API_URL +
          API.API_VER +
          apiEndPoint +
          "?company_id=" +
          business.profile.company_id +
          "&job_status=" +
          job_status +
          "&date=" +
          todaysDateString +
          "&type=" +
          user_job_type
        : API.API_URL +
          API.API_VER +
          apiEndPoint +
          "?company_id=" +
          business.profile.company_id +
          "&job_status=" +
          job_status +
          "&date=" +
          todaysDateString +
          "&type=" +
          user_job_type;

    return new Promise((resolve, reject) => {
      $.ajax({
        url: api_url,
        type: "GET",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
      }).then(
        async (res) => {
          if (res) {
            setLoading(false);
            if (
              (user &&
                user.role_id === "R003" &&
                (job_status === "Pending" ||
                  job_status === "Declined" ||
                  job_status === "Completed" ||
                  job_status === "ContractorDeclined" ||
                  job_status === "Bidded")) ||
              (user &&
                user.role_id === "R001" &&
                (job_status === "Completed" || job_status === "Billed"))
            ) {
              if (
                job_status === "Declined" ||
                job_status === "ContractorDeclined"
              ) {
                let jobs = [];
                for (const job of res[job_status]) {
                  jobs.push(job);
                }
                setJobs(res[job_status]);
                resolve(jobs);
              } else {
                setJobs(res[job_status]);
                resolve(res[job_status]);
              }
            } else {
              let jobs = res[job_status];
              setJobs(jobs);
            }
          }
        },
        (err) => {
          setLoading(false);
          Toastr.error("Something went wrong!");
        }
      );
    });
  };

  const onJobAccept = (values) => {
    let data = {
      form_input: {
        job_id: currentSelectedJob.job_id,
        company_id: user.company_id,
        business_name: business.profile.business_name,
        bid_amount: parseFloat(values.bidAmount),
        action: "submitBid",
        materialPrice: parseFloat(values.materialPrice),
        haulRate: parseFloat(values.haulRate),
      },
    };

    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.TRUCKER.BIDS,
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      async (res) => {
        if (res) {
          setOpen(false);
          form.resetFields();
          callGetJobs(jobStatus);
          setBidAmount(null);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const handleDecline = () => {
    let data = {
      form_input: {
        company_id: business.profile.company_id,
        job_id: currentSelectedJob.job_id,
        business_name: business.profile.business_name,
        action: "declineJob",
      },
    };

    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.TRUCKER.BIDS,
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user && user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          callGetJobs(jobStatus);
          setOpen(false);
          setCurrentSelectedJob([]);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const updateJobStatus = (rec, status) => {
    let data = {
      job_id: rec.job_id,
      bid_id: rec.bid && rec.bid.bid_id,
      status: status,
      business_name: rec.business_name,
      company_id: rec.company_id,
    };

    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.TRUCKER.BIDS,
      type: "PUT",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          Toastr.success("Job status updated successfully!", "Status");
          getJobs(jobStatus);
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
      }
    );
  };

  return (
    <div>
      {user && user.role_id === "R001" ? (
        <Bids
          job={jobs}
          loading={loading}
          fetchJobs={getJobs}
          jobStatus={jobStatus}
        />
      ) : (
        <React.Fragment>
          <div className="space-y-4">
            <div className="ant-table-custom-wrapper" xs={24} md={12}>
              <Table
                columns={table_columns}
                rowKey={(record) => record.job_id}
                dataSource={jobs}
                pagination={false}
                loading={loading}
              />
            </div>
          </div>

          <Drawer
            title={
              "Project Name: " +
              (currentSelectedJob && currentSelectedJob.project_name)
            }
            placement="right"
            onClose={onClose}
            open={open}
            mask={false}
          >
            {currentSelectedJob && (
              <React.Fragment>
                <Card title="Job Details">
                  <Descriptions column={1} items={filteredDetails} />
                </Card>
                <Form onFinish={onJobAccept} form={form} className="mt-4">
                  {enableMaterialPriceInput && (
                    <React.Fragment>
                      <Form.Item
                        name={"materialPrice"}
                        label={"Material Price"}
                        rules={[{ required: true }]}
                      >
                        <Input
                          style={{ width: "100%" }}
                          prefix="$"
                          placeholder="Material Price"
                          onChange={(value) => {
                            const haulRate = form.getFieldValue("haulRate");
                            const total = haulRate
                              ? (parseFloat(value.target.value) || 0) +
                                (parseFloat(haulRate) || 0)
                              : value.target.value;
                            form.setFieldsValue({
                              bidAmount: total.toString(),
                            });
                            setBidAmount(total.toString());
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name={"haulRate"}
                        label={"Haul Rate"}
                        rules={[{ required: true }]}
                      >
                        <Input
                          style={{ width: "100%" }}
                          prefix="$"
                          placeholder="Haul Rate"
                          onChange={(value) => {
                            const materialPrice =
                              form.getFieldValue("materialPrice");
                            const total = materialPrice
                              ? (parseFloat(value.target.value) || 0) +
                                (parseFloat(materialPrice) || 0)
                              : value.target.value;
                            form.setFieldsValue({
                              bidAmount: total.toString(),
                            });
                            setBidAmount(total.toString());
                          }}
                        />
                      </Form.Item>
                    </React.Fragment>
                  )}

                  <Form.Item
                    name={"bidAmount"}
                    label={"Bid Amount"}
                    rules={[{ required: true }]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      prefix="$"
                      disabled={enableMaterialPriceInput}
                      placeholder="Bid Amount"
                    />
                  </Form.Item>
                  {!currentSelectedJob.bid && (
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <Popconfirm
                        title="Decline Job"
                        description={"Are you sure to decline this Job?"}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={handleDecline}
                      >
                        <Button style={{ marginRight: "10px" }}>Decline</Button>
                      </Popconfirm>
                      <Button
                        type="primary"
                        onClick={async () => {
                          try {
                            await form.validateFields();
                            setIsContractModalVisible(true);
                          } catch (e) {
                            return;
                          }
                        }}
                      >
                        Submit Bid
                      </Button>
                    </div>
                  )}
                </Form>

                <ContractForm
                  file={biddingContract}
                  modalVisible={isContractModalVisible}
                  onCancel={() => setIsContractModalVisible(false)}
                  onAccept={() => {
                    form.submit();
                    setIsContractModalVisible(false);
                    // onJobAccept();
                  }}
                  disabledAcceptButton={false}
                />
              </React.Fragment>
            )}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
};

export default Jobs;
