import { API } from "../../config/AppConstants";
import { apiHelper } from "../instance";

export const submitInvoice = async (payload) => {
  const { data } = await apiHelper.post(
    API.API_URL + API.API_VER + API.API_ENDPOINTS.INVOICE.INVOICEINFO,
    payload
  );
  return data;
};

export const listInvoices = async ({ company_id, job_company_id }) => {
  const { data } = await apiHelper.get(
    API.API_URL + API.API_VER + API.API_ENDPOINTS.INVOICE.INVOICEINFO,
    {
      params: {
        action: "list",
        bid_company_id: company_id,
        job_company_id: job_company_id,
      },
    }
  );

  return data.invoices ?? [];
};
