import { useEffect, useState } from "react";

const Timer = ({
  initialSeconds,
  pause = false,
  showHours = true,
  showMinutes = true,
  showSeconds = true,
  timerSize = "default",
}) => {
  const [timer, setTimer] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    let elapsedSeconds = initialSeconds;

    const updateTimer = () => {
      const hours = String(Math.floor(elapsedSeconds / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((elapsedSeconds % 3600) / 60)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor(elapsedSeconds % 60)).padStart(2, "0"); // Ensure seconds are properly formatted
      setTimer({ hours, minutes, seconds });

      if (!pause) {
        elapsedSeconds += 1; // Increment time
      }
    };

    const interval = setInterval(updateTimer, 1000);
    updateTimer(); // Start immediately
    return () => clearInterval(interval);
  }, [initialSeconds, pause]);

  if (timerSize === "sm") {
    return (
      <div className="flex items-center">
        <h2 className=""> {timer.hours}</h2>
        <h2 className=""> :</h2>
        <h2 className=""> {timer.minutes}</h2>
        <h2 className=""> :</h2>
        <h2 className=""> {timer.seconds}</h2>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center gap-4 select-none">
      {showHours && (
        <div className="flex flex-col justify-center">
          <h2 className="lg:text-[100px] text-[50px] font-bold text-[#8EF5E2] text-center relative">
            {timer.hours}
            <span className="font-bold text-white text-sm ml-3 absolute sm:top-0 -top-2 sm:left-0 -left-2">
              Hrs
            </span>
          </h2>
        </div>
      )}
      {showHours && showMinutes && (
        <h2 className="lg:text-[100px] text-[50px] text-[#8EF5E2] text-center mb-2">
          :
        </h2>
      )}
      {showMinutes && (
        <div className="flex flex-col justify-center">
          <h2 className="lg:text-[100px] text-[50px] font-bold text-[#8EF5E2] text-center relative">
            {timer.minutes}
            <span className="font-bold text-white text-sm ml-3 absolute sm:top-0 -top-2 sm:left-0 -left-2">
              Min
            </span>
          </h2>
        </div>
      )}
      {showMinutes && showSeconds && (
        <h2 className="lg:text-[100px] text-[50px] text-[#8EF5E2] text-center mb-2">
          :
        </h2>
      )}
      {showSeconds && (
        <div className="flex flex-col justify-center">
          <h2 className="lg:text-[100px] text-[50px] font-bold text-[#8EF5E2] text-center relative">
            {timer.seconds}
            <span className="font-bold text-white text-sm ml-3 absolute sm:top-0 -top-2 sm:left-0 -left-2">
              Sec
            </span>
          </h2>
        </div>
      )}
    </div>
  );
};

export default Timer;
