import axios from "axios";

import { message } from "antd"; // Assuming you're using Ant Design's message component for notifications

import createAuthRefreshInterceptor from "axios-auth-refresh";
import $sessionStore from "../store/session";
import { API } from "../config/AppConstants";

const URL = API.API_URL + API.API_VER;
// const STORAGE_URL = `${import.meta.env.VITE_APP_STORAGE_URL}`;

export const getApiURL = () => {
  return URL;
};

// export const getStorageURL = () => {
//   return STORAGE_URL;
// };

const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const instance = axios.create({
  baseURL: URL,
  headers: DEFAULT_HEADERS,
});

instance.interceptors.request.use((request) => {
  const headers = $sessionStore.actions.getHeaders();
  if (headers) {
    request.headers = { ...request.headers, ...headers };
  }
  return request;
});

const refreshAuthToken = async (payload) => {
  const { data } = await axios.post(`${URL}/auth/refresh-token`, payload);

  return data;
};

const refreshTokenLogic = async (callbackRequest) => {
  try {
    const data = callbackRequest.response.data;
    //check if data has error
    if (data.error) {
      if (data.error.code === "TOKEN_EXPIRED") {
        window.location = "/";
      }
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const options = { statusCodes: [401] };

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Handle specific status codes or error scenarios
      switch (error.response.status) {
        case 400:
          // Suppress the error and show a custom message
          message.error("Bad Request: Please check your input.");
          break;
        case 401:
          message.error("Unauthorized: Please log in.");
          break;
        case 500:
          message.error("Server Error: Please try again later.");
          break;
        default:
          message.error("An error occurred. Please try again.");
      }
    } else if (error.request) {
      // The request was made but no response was received
      message.error("Network error: Please check your connection.");
    } else {
      // Something else caused an error
      message.error("An error occurred. Please try again.");
    }

    // Optionally return a rejected promise to propagate the error if needed,
    // or return a resolved promise to silence the error.
    return Promise.reject(error);
  }
);

export const googleApiHelper = axios.create({
  baseURL: "https://maps.googleapis.com/maps/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

createAuthRefreshInterceptor(instance, refreshTokenLogic, options);

export const apiHelper = instance;
