import React, { useEffect, useRef } from "react";
import livePic from "../assets/img/construction.png";
import { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Steps,
} from "antd";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import $ from "jquery";
import SubmitButton from "../components/Button";
import { useNavigate } from "react-router-dom";
import Toastr from "toastr";
import pdf from "../assets/docs/tc.pdf";
import { API } from "../config/AppConstants";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import logo from "../assets/img/logo_transparent_.png";
import ContractForm from "../components/modules/ContractForm/ContractForm.view";

const { Step } = Steps;

const Register = () => {
  const [form] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState({
    label: "Contractor",
    value: "R001",
    role_type: "global",
  });
  const [register, setRegister] = useState(false);
  const [value, setValue] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [globalRoles, setGlobalRoles] = useState([]);
  const navigate = useNavigate();
  const [isAccepted, setIsAccepted] = useState(false);
  const pageRefs = useRef([]);
  const [current, setCurrent] = useState(0);
  const [checked, setChecked] = useState(false);

  const onContractViewed = () => {
    setIsAccepted(true);
  };

  useEffect(() => {
    getRoles();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.length === 1 &&
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const pageNumber = parseInt(entry.target.dataset.pageNumber, 12);
              if (numPages === pageNumber || pageNumber === numPages - 1) {
                setIsAccepted(true);
              }
            }
          });
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: "0px",
        threshold: 0.1, // Adjust the threshold value as needed
      }
    );

    pageRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      if (pageRefs.current) {
        pageRefs.current.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref);
          }
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numPages]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    pageRefs.current = Array(numPages).fill(null);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const onSubmission = async () => {
    setModalVisible(false);
    setRegister(true);
    let data = {
      form_input: {
        user_login: value.user_login,
        password: value.password,
        role_id: selectedRole.value,
        role_type: selectedRole.role_type,
        first_name: value.first_name.trim(),
        last_name: value.last_name.trim(),
        phone: value.phone,
        email: value.user_login,
        billing_address: JSON.stringify({
          address: value["address_1"],
          city: value["address_1_city"],
          state: value["address_1_state"],
          zipcode: value["address_1_zipcode"],
        }),
        physical_address: JSON.stringify({
          address: value["address_2"],
          city: value["address_2_city"],
          state: value["address_2_state"],
          zipcode: value["address_2_zipcode"],
        }),
        business_name: value.business_name.trim(),
        fed_tax_id: value.fed_tax_id,
      },
    };

    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.LOGIN.SIGNUP,
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          if (!(Object.keys(res).length === 0)) {
            Toastr.success("User registered successfully!");
            navigate("/");
          } else {
            Toastr.error("User already exist!");
          }
          setRegister(false);
        }
      },
      (err) => {
        if (err.responseJSON && err.responseJSON.message) {
          Toastr.error(err.responseJSON.message);
        } else {
          Toastr.error("Something went wrong!");
        }
        setRegister(false);
      }
    );
  };

  const getRoles = () => {
    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.ROLE.ROLES +
        "?role_type=global",
      type: "GET",
      dataType: "json",
    })
      .then(async (res) => {
        let globalRoles = [];
        for (const role of res) {
          globalRoles.push({
            label: role.role_name,
            value: role.role_id,
            role_type: role.role_type,
          });
        }
        setGlobalRoles(globalRoles);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        setGlobalRoles([]);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateLength = (_, value) => {
    if (_.field === "phone") {
      const regPattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (value && !regPattern.test(value)) {
        return Promise.reject("Please enter valid number.");
      }
    } else {
      const regPattern = /^\d{2}-?\d{7}$/;
      if (value && !regPattern.test(value)) {
        return Promise.reject("Please enter valid Federal Tax ID.");
      }
    }
    return Promise.resolve();
  };

  const zipCodeValidator = (_, value) => {
    let zipPattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (value && !zipPattern.test(value)) {
      return Promise.reject("Enter Valid Zip Code");
    }
    return Promise.resolve();
  };

  const validatePassword = (_, value) => {
    if (value && value.length < 5) {
      return Promise.reject("Password must be atleast 5 characters long.");
    }
    return Promise.resolve();
  };

  const next = () => {
    form
      .validateFields()
      .then(() => {
        setCurrent(current + 1);
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = () => {
    setModalVisible(true);
    setValue(form.getFieldsValue(true));
    console.log("Success:", form.getFieldsValue(true));
  };

  const FederalTaxIdInput = ({ value = "", onChange }) => {
    const handleChange = (e) => {
      let inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
      if (inputValue.length > 2) {
        inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
      }
      onChange(inputValue);
    };
    return (
      <Input
        maxLength={10}
        style={{ width: "100%" }}
        value={value}
        onChange={handleChange}
      />
    );
  };

  const handleCheckbox = (e) => {
    setChecked(e.target.checked);
    let formVal = form.getFieldsValue(true);
    if (e.target.checked)
      form.setFieldsValue({
        address_2: formVal.address_1,
        address_2_city: formVal.address_1_city,
        address_2_state: formVal.address_1_state,
        address_2_zipcode: formVal.address_1_zipcode,
      });
    else
      form.setFieldsValue({
        address_2: "",
        address_2_city: "",
        address_2_state: "",
        address_2_zipcode: "",
      });
  };

  const steps = [
    {
      title: "Basic Info",
      content: (
        <div>
          <Col xs={24} md={24}>
            <Form.Item key={0} label={"Role"} name={"role"}>
              <Select
                onChange={(_, val) => {
                  form.resetFields();
                  setRegister(false);
                  setSelectedRole(val);
                }}
                options={globalRoles}
                values={selectedRole}
                defaultValue={selectedRole}
              />
            </Form.Item>
          </Col>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="business_name"
                label="Business name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Business name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="fed_tax_id"
                label="Federal Tax ID"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Federal Tax ID",
                  },
                  { validator: validateLength },
                ]}
              >
                <FederalTaxIdInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_1"
                label="Billing Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Billing Address",
                  },
                ]}
              >
                <TextArea rows={2} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_1_city"
                label="City"
                rules={[
                  { required: true, message: "Please enter your last name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_1_state"
                label="State"
                rules={[{ required: true, message: "Please enter your State" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_1_zipcode"
                label="Zip Code"
                rules={[
                  { required: true, message: "Please enter your Zip Code" },
                  { validator: zipCodeValidator },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      title: "Location",
      content: (
        <div>
          <Col span={24} style={{ marginBottom: "10px" }}>
            <Checkbox checked={checked} onChange={handleCheckbox}>
              Physical address same as business address
            </Checkbox>
          </Col>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_2"
                label="Physical Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Physical Address",
                  },
                ]}
              >
                <TextArea rows={2} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_2_city"
                label="City"
                rules={[{ required: true, message: "Please enter your City" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_2_state"
                label="State"
                rules={[{ required: true, message: "Please enter your State" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_2_zipcode"
                label="Zip Code"
                rules={[
                  { required: true, message: "Please enter your Zip Code" },
                  { validator: zipCodeValidator },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      title: "Primary Admin",
      content: (
        <div>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                  { required: true, message: "Please enter your first name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                  { required: true, message: "Please enter your Last name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="user_login"
                label="Email / Login ID"
                rules={[
                  { required: true, message: `Email / Login ID is required!` },
                  { type: "email", message: "The input is not valid E-mail!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: `Password is required!` },
                  { validator: validatePassword },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} md={12}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                { required: true, message: "Please enter your Phone" },
                { validator: validateLength },
              ]}
            >
              <Input maxLength={11} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </div>
      ),
    },
  ];

  return (
    <Flex
      justify={"center"}
      style={{
        minHeight: "100vh",
        backgroundImage:
          "linear-gradient(rgba(0,0,0, 0.8), rgba(0,0,0, 0.8)), url(" +
          livePic +
          ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <Flex justify={"center"} align={"center"}>
        <div className="auth-box auth-box-bg">
          <div style={{ textAlign: "center" }}>
            <span>
              <img
                className="pb-1 "
                src={logo}
                alt="logo"
                style={{ width: "250px" }}
              />
            </span>
          </div>
          <Title
            type="primary"
            level={4}
            style={{ textAlign: "center", marginBottom: "50px" }}
          >
            Business Registration
          </Title>
          <div>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              style={{ marginTop: "25px" }}
            >
              <div>{steps[current].content}</div>
              <div className="steps-action align-right">
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={() => next()}>
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <SubmitButton form={form}>
                    {register ? "Registering..." : "Submit"}
                  </SubmitButton>
                )}
                {current > 0 && (
                  <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                    Previous
                  </Button>
                )}
                {current === 0 && (
                  <Button
                    style={{ margin: "0 8px" }}
                    onClick={() => navigate("/")}
                  >
                    Cancel
                  </Button>
                )}
              </div>
            </Form>
          </div>

          <ContractForm
            file={pdf}
            modalVisible={modalVisible}
            handleModalClose={() => setModalVisible(false)}
            onAccept={onSubmission}
            onCancel={handleModalClose}
            onContractViewed={onContractViewed}
            disabledAcceptButton={!isAccepted}
          />
        </div>
      </Flex>
    </Flex>
  );
};

export default Register;
