import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import SubmitButton from "../../components/Button";
import { AiOutlineDelete } from "react-icons/ai";
import $ from "jquery";
import Toastr from "toastr";
import useSession from "../../context/SessionContext";
import { API } from "../../config/AppConstants";

const Fleet = () => {
  const { user } = useSession();
  const [form] = Form.useForm();
  const [fleetInfo, setFleetInfo] = useState([]);
  const [options, setOptions] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [save, setSave] = useState(false);
  const [update, setUpdate] = useState(false);

  const fleetInfoWithKeys = fleetInfo.map((item, index) => ({
    ...item,
    key: item._id, // Use a unique field like vehicle_nr, or fallback to index
  }));

  const trucker_columns = [
    {
      title: "Truck Number",
      dataIndex: "truck_number",
      render: (truck_number, record, i) => {
        return <span className="anchor_link">{truck_number}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
      onCell: (record, recordIndex) => ({
        onClick: () => onCellSelected(record),
      }),
    },
    {
      title: "Type",
      dataIndex: "truck_type",
      render: (truck_type, record, i) => {
        return <span>{truck_type}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Created By",
      dataIndex: "user_login",
      render: (user_login, record, i) => {
        return <span>{user_login}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "TimeStamp",
      dataIndex: "created_ts",
      render: (created_ts, record, i) => {
        return <span>{new Date(created_ts).toLocaleString()}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
  ];

  const onCellSelected = (record) => {
    setSelectedRow(record);
    form.setFieldsValue(record);
    setModalVisible(true);
  };

  const rowSelection = {
    selectedRowKeys, // Ensure selected keys are passed here
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys); // Correctly update selected row keys
    },
  };

  useEffect(() => {
    if (user) fetchFleetInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitFleetInfo = (values) => {
    if (selectedRow) {
      updateFleet(values);
    } else {
      saveFleet(values);
    }
  };

  const updateFleet = (values) => {
    setUpdate(true);
    let modifiedFields = getModifiedFields(selectedRow, values);
    let form_input = { vehicle_nr: selectedRow.vehicle_nr };
    for (const val in modifiedFields) {
      form_input[val] = modifiedFields[val];
    }
    let data = { form_input: form_input };
    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.FLEET.FLEET,
      type: "PUT",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          Toastr.success("Fleet Updated successfully!");
          form.resetFields();
          setModalVisible(false);
          fetchFleetInfo();
          setSelectedRow(null);
          setUpdate(false);
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
        setUpdate(false);
      }
    );
  };

  const getModifiedFields = (obj1, obj2) => {
    const differingValues = {};
    for (let key in obj1) {
      if (obj2.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        differingValues[key] = obj2[key];
      }
    }
    return differingValues;
  };

  const saveFleet = (values) => {
    setSave(true);
    let data = {
      form_input: {
        company_id: user.company_id,
        user_login: user.user_login,
        user_email: user.email,
        truck_number: values.truck_number,
        truck_type: values.truck_type,
      },
    };
    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.FLEET.FLEET,
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          Toastr.success("Fleet created successfully!");
          form.resetFields();
          fetchFleetInfo();
          setModalVisible(false);
          setSave(false);
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
        setSave(false);
      }
    );
  };

  const fetchFleetInfo = () => {
    setLoading(true);
    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.FLEET.FLEET +
        "?company_id=" +
        user.company_id +
        "&useGet=0",
      type: "GET",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    }).then(
      (res) => {
        if (res) {
          setFleetInfo(res);
          setLoading(false);
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
        setLoading(false);
      }
    );
  };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  //   getCheckboxProps: (record) => ({
  //     id: record.key,
  //     // Ensure that each checkbox is uniquely identified
  //     checked: selectedRowKeys.includes(record.vehicle_nr), // Ensure correct selection
  //   }),
  // };

  // rowSelection object indicates the need for row selection
  // rowSelection object indicates the need for row selection

  const addFleet = () => {
    form.setFieldsValue({ truck_number: "", type: "" });
    setModalVisible(true);
  };

  const deleteFleet = (fleet) => {
    return new Promise((resolve, reject) => {
      let data = {
        form_input: {
          vehicle_nr: fleet,
        },
      };
      $.ajax({
        url: API.API_URL + API.API_VER + API.API_ENDPOINTS.FLEET.FLEET,
        type: "DELETE",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        data: JSON.stringify(data),
      }).then(
        (res, textStatus, xhr) => {
          if (xhr.status === 200) {
            resolve("fleet deleted successfully!");
          }
        },
        (xhr) => {
          if (xhr.status === 200) {
            resolve("fleet deleted successfully!");
          }
          resolve("Something went wrong!");
        }
      );
    });
  };

  const handleDelete = async () => {
    for (const vehicle_nr of selectedRowKeys) {
      await deleteFleet(vehicle_nr);
    }
    Toastr.success("Fleet deleted successfully!");
    fetchFleetInfo();
    setSelectedRowKeys([]);
  };

  const type = [
    {
      label: "Standard Dump Truck Steel Box",
      value: "Standard Dump Truck Steel Box",
    },
    {
      label: "Standard Dump Truck Aluminum Box",
      value: "Standard Dump Truck Aluminum Box",
    },
    { label: "Super Dump Truck", value: "Super Dump Truck" },
    { label: "Semi-Truck Side Dump", value: "Semi-Truck Side Dump" },
    {
      label: "Semi-Truck Bottom “Belly” Dump",
      value: "Semi-Truck Bottom “Belly” Dump",
    },
    { label: "Semi-Truck End Dump", value: "Semi-Truck End Dump" },
    { label: "Enclosed", value: "Enclosed" },
    { label: "Transfer", value: "Transfer" },
  ];
  const showPagination = fleetInfo && fleetInfo.length > 10;

  return (
    <React.Fragment>
      <Row>
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" onClick={() => addFleet()}>
            Add Fleet
          </Button>
          <Popconfirm
            title="Delete Fleet"
            description={
              selectedRowKeys.length > 1
                ? "Are you sure to delete these Fleets?"
                : "Are you sure to delete this Fleet?"
            }
            okText="Yes"
            cancelText="No"
            onConfirm={handleDelete}
          >
            <Button
              type="primary"
              style={{ marginLeft: "6px" }}
              disabled={!selectedRowKeys.length > 0}
              danger
              icon={<AiOutlineDelete size={20} />}
            ></Button>
          </Popconfirm>
        </Col>
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col span={24}>
          <div className="ant-table-custom-wrapper">
            <Table
              columns={trucker_columns}
              dataSource={fleetInfoWithKeys}
              rowKey={(record) => record._id}
              rowSelection={rowSelection}
              pagination={showPagination ? {} : false}
              loading={loading}
            />
          </div>
        </Col>
      </Row>

      <Modal
        title={selectedRow ? "Edit Fleet" : "Add Fleet"}
        open={modalVisible}
        maskClosable={false}
        width={600}
        onCancel={() => {
          setModalVisible(false);
          setSelectedRow(null);
        }}
        style={{ top: 60 }}
        destroyOnClose={true}
        footer={false}
      >
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            maxWidth: "100%",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="nest-messages"
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 22 }}
            style={{ maxWidth: 700, margin: "15px 30px" }}
            autoComplete="off"
            onFinish={onSubmitFleetInfo}
          >
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"truck_number"}
                  label={"Truck Number"}
                  rules={[
                    { required: true, message: `Truck Number is required!` },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"truck_type"}
                  label={"Type"}
                  rules={[{ required: true, message: `Type is required!` }]}
                >
                  <Select
                    options={type}
                    values={options}
                    onChange={(selected) => {
                      setOptions(selected);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "15px",
                marginRight: "18px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
              <SubmitButton form={form}>
                {selectedRow
                  ? update
                    ? "Updating..."
                    : "Update"
                  : save
                  ? "Saving..."
                  : "Save"}
              </SubmitButton>
            </Col>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Fleet;
