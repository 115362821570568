// PaymentModal.js
import React, { useState } from "react";
import { Modal, Form, Input, Radio, Button, Checkbox, message } from "antd";

const PaymentModal = ({
  visible,
  onSubmit,
  amount,
  onCancel,
  isSubmiting = false,
}) => {
  const [form] = Form.useForm();
  const [paymentMethod, setPaymentMethod] = useState("card");

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleFinish = (values) => {
    onSubmit();
  };

  const handleFinishFailed = (errorInfo) => {
    message.error("Please check the form for errors.");
  };

  const handleAccountNumberChange = (e) => {
    let { value } = e.target;
    const valueWithoutSpace = value.replace(/\s?/g, "");
    const valueWithSpaces = valueWithoutSpace.replace(/(\d{4})/g, "$1 ");
    form.setFieldsValue({ accountNumber: valueWithSpaces });
  };

  const handleCardNumberChange = (e) => {
    let { value } = e.target;
    const valueWithoutSpace = value.replace(/\s?/g, "");
    const valueWithSpaces = valueWithoutSpace.replace(/(\d{4})/g, "$1 ");
    form.setFieldsValue({ cardNumber: valueWithSpaces });
  };

  const CardPaymentForm = () => {
    if (paymentMethod !== "card") return null;
    return (
      <React.Fragment>
        <Form.Item
          label="Card Number"
          name="cardNumber"
          rules={[
            { required: true, message: "Please input your card number!" },
          ]}
        >
          <Input
            onChange={handleCardNumberChange}
            placeholder="1234 5678 9101 1121"
          />
        </Form.Item>
        <Form.Item
          label="Expiration Date"
          name="expirationDate"
          rules={[
            {
              required: true,
              message: "Please input the expiration date!",
            },
          ]}
        >
          <Input placeholder="MM/YY" />
        </Form.Item>
        <Form.Item
          label="CVV"
          name="cvv"
          rules={[{ required: true, message: "Please input the CVV!" }]}
        >
          <Input placeholder="123" />
        </Form.Item>
        <Form.Item name="saveCardDetails" valuePropName="checked">
          <Checkbox>Save card details</Checkbox>
        </Form.Item>
      </React.Fragment>
    );
  };

  const BankPaymentForm = () => {
    if (paymentMethod !== "bank") return null;
    return (
      <React.Fragment>
        <Form.Item
          label="Account Number"
          name="accountNumber"
          rules={[
            { required: true, message: "Please input your account number!" },
          ]}
        >
          <Input
            placeholder="1234 5678 9101 1121"
            onChange={handleAccountNumberChange}
          />
        </Form.Item>
      </React.Fragment>
    );
  };

  return (
    <Modal open={visible} onCancel={onCancel} footer={null}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
      >
        <Form.Item label="Pay With:" name="paymentMethod" initialValue="card">
          <Radio.Group onChange={handlePaymentMethodChange}>
            <Radio value="card">Card</Radio>
            <Radio value="bank">Bank</Radio>
            <Radio value="transfer">Transfer</Radio>
          </Radio.Group>
        </Form.Item>

        <CardPaymentForm />
        <BankPaymentForm />

        <Form.Item>
          <Button loading={isSubmiting} type="primary" htmlType="submit" block>
            Pay ${amount}
          </Button>
        </Form.Item>
      </Form>
      <p style={{ fontSize: "12px", color: "#888" }}>
        Your personal data will be used to process your order, support your
        experience throughout this website, and for other purposes described in
        our privacy policy.
      </p>
    </Modal>
  );
};

export default PaymentModal;
