class HourlyTripHelper {
  constructor(trip) {
    this.trip = trip;
  }

  get sessions() {
    return this.trip?.sessions ?? [];
  }

  get isTripPaused() {
    const sessions = this.sessions;
    const currentSession = sessions[sessions.length - 1];
    const currentTripStartTime = currentSession?.start_time;
    const currentTripEndTime = currentSession?.end_time;
    let pause = currentSession == null;
    if (currentSession) {
      if (currentTripStartTime != null && currentTripEndTime != null) {
        pause = true;
      } else {
        pause = false;
      }
    }
    return pause;
  }

  calculateEffectiveTripTime = () => {
    const trips = this.sessions;
    let totalEffectiveTime = 0;
    for (let i = 0; i < trips.length; i++) {
      const trip = trips[i];
      if (trip.start_time) {
        const startTime = new Date(trip.start_time).getTime();
        const endTime = trip.end_time
          ? new Date(trip.end_time).getTime()
          : new Date().getTime();
        const tripDuration = (endTime - startTime) / 1000;
        totalEffectiveTime += tripDuration;
      }
    }
    return totalEffectiveTime;
  };

  calculateTotalHoursWorked = () => {
    const totalEffectiveTime = this.calculateEffectiveTripTime();
    const totalHoursWorked = totalEffectiveTime / 3600;
    return totalHoursWorked.toFixed(2);
  };
}

export default HourlyTripHelper;

// {
//     "_id": "66f4f11ec21efc82adf7d2ff",
//     "job_assignment_id": "66f4f06f0d4132d97cfcf188",
//     "trip_date": "2024-09-26T00:00:00Z",
//     "job_id": "ead0cce7-b16c-4bc8-b6eb-c08b41824286",
//     "haul_id": "66f4f06f9d035c795e4378a3",
//     "sessions": [
//         {
//             "start_time": "2024-09-26T05:29:07.000Z",
//             "status": "paused",
//             "end_time": "2024-09-26T05:42:22.000Z"
//         },
//         {
//             "start_time": "2024-09-26T05:44:50.000Z",
//             "status": "paused",
//             "end_time": "2024-09-26T05:44:54.000Z"
//         },
//         {
//             "start_time": "2024-09-26T05:45:01.000Z",
//             "status": "completed",
//             "end_time": "2024-09-26T05:46:05.000Z"
//         }
//     ],
//     "load_quantity": 0,
//     "status": "completed"
// }
