import toastr from "toastr";

class GeoLocationHelper {
  /**
   * Calculates whether a given location is within a specified radius of a target location.
   *
   * @param {Object} currentLocation - The current location with 'lat' and 'lng' properties.
   * @param {Object} targetLocation - The target location with 'lat' and 'lng' properties.
   * @param {number} radiusInMeters - The radius in meters to check against.
   * @return {boolean} True if the current location is within the specified radius of the target location, false otherwise.
   */
  static isLocationInRadius(currentLocation, targetLocation, radiusInMeters) {
    const { lat: currentLat, lng: currentLng } = currentLocation;
    const { lat: targetLat, lng: targetLng } = targetLocation;

    // Radius of the Earth in meters
    const R = 6371000;

    // Convert degrees to radians
    const toRadians = (degrees) => (degrees * Math.PI) / 180;

    // Haversine formula
    const dLat = toRadians(targetLat - currentLat);
    const dLng = toRadians(targetLng - currentLng);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(currentLat)) *
        Math.cos(toRadians(targetLat)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    // Convert distance from meters to miles
    const distanceInMiles = distance * 0.000621371;

    if (distance > radiusInMeters) {
      toastr.info(
        `You are ${distanceInMiles.toFixed(0)} miles away from Destination`
      );
    }

    return distance <= radiusInMeters;
  }
}

export default GeoLocationHelper;
